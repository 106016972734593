import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n, { translate } from '@utils/i18n';
import VisitItem from './VisitItem';
import { fetchEvents, fetchTechnicians, fetchVisits, saveVisit } from '../DAL/dataAccess';
import {
  setStatus,
  setVisitDateFilterMessage,
  setVisits,
  shiftSaveQueue,
  status as mapStatuses
} from '../Redux/visitsSlice';
import { setStatus as setSchedulerStatus, status as schedulerStatuses } from '../Redux/schedulerSlice';

const namespace = 'features.map_scheduler';

function VisitsPane() {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.map.filters);
  const mapActive = useSelector((state) => state.map.mapActive);

  const visitStatus = useSelector((state) => state.map.status);
  const saveQueue = useSelector((state) => state.map.saveQueue);

  useEffect(() => {
    if (moment(filters.values.visitDate.endDate).diff(moment(filters.values.visitDate.startDate), 'days') > 31) {
      dispatch(setVisitDateFilterMessage('Please limit range to 1 month'));
      return;
    }

    dispatch(setStatus(mapStatuses.LOADING));
    fetchVisits({ filterValues: filters.values })
      .then((result) => {
        dispatch(setVisits(result));
        dispatch(setStatus(mapStatuses.READY));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setStatus(mapStatuses.ERROR));
      });
  }, [filters]);

  useEffect(() => {
    if (saveQueue.length > 0) {
      dispatch(setSchedulerStatus(schedulerStatuses.SAVING));
      const event = saveQueue[0];

      saveVisit(event)
        .then((result) => {
          dispatch(shiftSaveQueue());
          dispatch(setSchedulerStatus(schedulerStatuses.READY));
        })
        .catch((error) => {
          console.error(error);
          dispatch(setSchedulerStatus(schedulerStatuses.ERROR));
        });
    }
  }, [saveQueue]);

  const conditionalClass = () => {
    return !mapActive ? '--x-full' : '';
  };

  const renderTableHeaders = () => {
    if (visitStatus === mapStatuses.READY && !mapActive) {
      return (
        <div
          style={{
            width: 'calc(100% + 4.8rem)',
            display: 'flex',
            justifyContent: 'space-between',
            margin: '-1.5rem -2.4rem',
            padding: '1.6rem 2.4rem'
          }}>
          <small style={{ flex: '0 0 8%' }}>Type</small>
          <small style={{ flex: '0 0 10%' }}>Building</small>
          <small style={{ flex: '0 0 15%' }}>Address</small>
          <small style={{ flex: '0 0 10%' }}>Scheduled Date</small>
          <small style={{ flex: '0 0 8%' }}>Status</small>
          <small style={{ flex: '0 0 5%' }}>Frequency</small>
          <small style={{ flex: '0 0 10%' }}>Description</small>
          <small style={{ flex: '0 0 10%' }}>Technician</small>
          <small style={{ flex: '0 0 10%' }}>Duration</small>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`map__pane${conditionalClass()}`}>
      <div className="pane__header">
        <h2 className="pane__title">{translate('visits.title', { namespace })}</h2>
        {visitStatus === mapStatuses.LOADING && <Loading />}
        {visitStatus === mapStatuses.ERROR && <Error />}
        {renderTableHeaders()}
      </div>
      {visitStatus === mapStatuses.READY && <VisitsList />}
    </div>
  );
}

function Error() {
  return <span>An Error Occurred.</span>;
}

function Loading() {
  return (
    <div className="qmb-loading--32--alt" data-title={I18n.t('generic.loading')}>
      <svg role="img">
        <use href="/map.svg#load-spinner" />
      </svg>
    </div>
  );
}

function VisitsList() {
  const visits = useSelector((state) => state.map.visits);

  return (
    <ul className="pane__list" style={{ overflowY: 'auto' }}>
      {visits
        .slice()
        .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))
        .map((v) => (
          <VisitItem visit={v} key={`visit_item_${v.type}_${v.id}`} />
        ))}
    </ul>
  );
}

export default VisitsPane;
