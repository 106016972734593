import DeficiencyColumns from '../tableDefinitions/deficiencyColumns';
import HighPriorityColumns from '../tableDefinitions/highPriorityColumns';
import InspectionColumns from '../tableDefinitions/inspectionColumns';
import InvoiceColumns from '../tableDefinitions/invoiceColumns';
import PaymentColumns from '../tableDefinitions/paymentColumns';
import ProposalColumns from '../tableDefinitions/proposalColumns';
import ServiceColumns from '../tableDefinitions/serviceColumns';
import { DashboardEntityType } from './constants';

export const ActionTypes = {
  CLOSE_MODAL: 'CLOSE_MODAL',
  STOP_LOADING: 'STOP_LOADING',
  START_LOADING: 'START_LOADING',
  LOAD_DATA: 'LOAD_DATA',
  LOAD_STATUSES_SELECTION: 'LOAD_STATUSES_SELECTION'
};

const ColumnsStructureData = {
  [DashboardEntityType.Inspections]: InspectionColumns,
  [DashboardEntityType.Invoices]: InvoiceColumns,
  [DashboardEntityType.WorkOrders]: ServiceColumns,
  [DashboardEntityType.Deficiencies]: DeficiencyColumns,
  [DashboardEntityType.Payments]: PaymentColumns,
  [DashboardEntityType.Proposals]: ProposalColumns,
  [DashboardEntityType.HighPriorities]: HighPriorityColumns
};

export function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.CLOSE_MODAL:
      return {
        ...state,
        isModalOpen: false,
        loading: false,
        tableData: []
      };
    case ActionTypes.LOAD_STATUSES_SELECTION:
      return {
        ...state,
        statusesSelection: action.payload.statusesSelection
      };
    case ActionTypes.START_LOADING:
      return {
        ...state,
        entityType: action.payload.entityType,
        isModalOpen: true,
        loading: true,
        isToday: action.payload.isToday,
        statusSelected: action.payload.statusSelected
      };
    case ActionTypes.LOAD_DATA:
      return {
        ...state,
        entityType: action.payload.entityType,
        tableData: action.payload.tableData || [],
        columnsStructureData: ColumnsStructureData[action.payload.entityType],
        loading: false
      };
    default:
      return state;
  }
}
