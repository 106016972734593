import { accountPath, buildingPath, inspectionPath, deficienciesPath } from '../../../routes';

export default ({ screen, screenId }) => {
  switch (screen) {
    case 'account':
      return accountPath({ id: screenId, anchor: 'deficiencies', _options: true });
    case 'building':
      return buildingPath({ id: screenId, anchor: 'deficiencies', _options: true });
    case 'inspection':
      return inspectionPath(screenId);
    default:
      return deficienciesPath();
  }
};
