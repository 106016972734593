import React, { useCallback, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import KendoActionsCell from '@components/Kendo/ActionsCell';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox';
import I18n, { translate } from '@utils/i18n';
import ResolveModal from './ResolveModal';
import ColumnMenuContext from './ColumnMenuContext';
import redirectPath from '../helpers/redirectPath';
import { editDeficiencyPath, deficiencyPath, unresolveDeficiencyPath } from '../../../routes';
import 'yet-another-react-lightbox/styles.css';

const namespace = 'features.deficiencies_index.actions_cell';

function ActionsCell({ colSpan, dataItem, field, className, ariaColumnIndex, style }) {
  const { screen, screenId } = useContext(ColumnMenuContext);
  const [photosDialogVisible, setPhotosDialogVisible] = useState(false);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [resolveDialogVisible, setResolveDialogVisible] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(-1);

  const authToken = useMemo(() => document.querySelector('meta[name="csrf-token"]').getAttribute('content'), []);

  const onUnresolve = useCallback(() => {
    fetch(`${unresolveDeficiencyPath({ id: dataItem.id })}?authenticity_token=${authToken}`, { method: 'POST' }).then(
      () => window.location.reload()
    );
  }, []);

  const onShowPhotos = useCallback(() => togglePhotosDialog(), []);
  const onDelete = useCallback(() => toggleDeleteDialog(), []);
  const onResolve = useCallback(() => toggleResolveDialog(), []);

  const togglePhotosDialog = useCallback(() => setPhotosDialogVisible((prev) => !prev), []);
  const toggleDeleteDialog = useCallback(() => setDeleteDialogVisible((prev) => !prev), []);
  const toggleResolveDialog = useCallback(() => setResolveDialogVisible((prev) => !prev), []);

  const photos = useMemo(() => dataItem.photos.map((photo) => ({ src: photo.large, width: null, height: null })), []);
  const thumbs = useMemo(() => dataItem.photos.map((photo) => ({ src: photo.thumb, width: null, height: null })), []);

  const actionsList = useMemo(() => {
    const list = [];
    if (dataItem.isResolved) {
      list.push({
        title: translate('unresolve', { namespace }),
        faClass: 'fa-plus',
        onClick: onUnresolve
      });
    } else {
      list.push({
        title: translate('resolve', { namespace }),
        faClass: 'fa-square-check',
        onClick: onResolve
      });
    }

    list.push({
      title: translate('edit', { namespace }),
      faClass: 'fa-pen-to-square',
      link: editDeficiencyPath({ id: dataItem.id, redirect_to: redirectPath({ screen, screenId }), _options: true })
    });

    if (dataItem.photos.length > 0) {
      list.push({
        title: translate('view_photos', { namespace }),
        faClass: 'fa-images',
        onClick: onShowPhotos
      });
    }

    list.push({
      title: translate('delete', { namespace }),
      faClass: 'fa-trash-can',
      onClick: onDelete
    });

    return list;
  }, []);

  const deletePath = useMemo(
    () => deficiencyPath({ id: dataItem.id, redirect_path: redirectPath({ screen, screenId }), _options: true }),
    []
  );

  return (
    <>
      <KendoActionsCell
        colSpan={colSpan}
        dataItem={dataItem}
        field={field}
        className={className}
        ariaColumnIndex={ariaColumnIndex}
        style={style}
        actionsList={actionsList}
      />

      {photosDialogVisible && (
        <Dialog
          title={translate('deficiency_photos', { namespace })}
          className="qmb-dialog"
          onClose={togglePhotosDialog}>
          <Gallery images={thumbs} enableImageSelection={false} onClick={(number) => setPhotoIndex(number)} />
          <Lightbox open={photoIndex >= 0} index={photoIndex} close={() => setPhotoIndex(-1)} slides={photos} />
        </Dialog>
      )}

      {deleteDialogVisible && (
        <Dialog title={translate('confirm', { namespace })} className="qmb-dialog" onClose={toggleDeleteDialog}>
          <p>{translate('confirm_delete', { namespace })}</p>
          <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
            <form action={deletePath} method="POST">
              <input type="hidden" value="delete" name="_method" />
              <input name="authenticity_token" type="hidden" value={authToken} />
              <button type="button" className="qmb-button" onClick={toggleDeleteDialog}>
                {I18n.t('generic.cancel')}
              </button>
              <button type="submit" className="qmb-button--submit">
                {I18n.t('generic.ok')}
              </button>
            </form>
          </div>
        </Dialog>
      )}

      {resolveDialogVisible && <ResolveModal toggleDialog={toggleResolveDialog} deficiencyId={dataItem.id} />}
    </>
  );
}

ActionsCell.propTypes = {
  ariaColumnIndex: PropTypes.number.isRequired,
  style: PropTypes.object,
  field: PropTypes.string.isRequired,
  className: PropTypes.string,
  colSpan: PropTypes.number.isRequired,
  dataItem: PropTypes.shape({
    isResolved: PropTypes.bool,
    photos: PropTypes.arrayOf(PropTypes.object),
    path: PropTypes.string,
    id: PropTypes.number
  }).isRequired
};

ActionsCell.defaultProps = {
  style: null,
  className: ''
};

export default ActionsCell;
