import React, { useState, useCallback, useRef, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { TextArea } from '@progress/kendo-react-inputs';
import I18n, { translate } from '@utils/i18n';
import redirectPath from '../helpers/redirectPath';
import { resolveDeficienciesPath } from '../../../routes';
import ColumnMenuContext from './ColumnMenuContext';

const namespace = 'features.deficiencies_index.update_status_modal';

function ResolveModal({ toggleDialog, deficiencyId }) {
  const form = useRef();
  const { deficiencyStatusesForSelect, resolutionStatusesForSelect, screen, screenId } = useContext(ColumnMenuContext);
  const defaultDeficiencyStatus = deficiencyStatusesForSelect[2];
  const defaultResolutionStatus = resolutionStatusesForSelect[0];

  const [isResolved, setIsResolved] = useState(false);
  const [deficiencyStatus, setDeficiencyStatus] = useState(defaultDeficiencyStatus);
  const [resolutionStatus, setResolutionStatus] = useState(defaultResolutionStatus);
  const [notes, setNotes] = useState('');

  const clearForm = () => {
    toggleDialog();
    setIsResolved(false);
    setDeficiencyStatus(defaultDeficiencyStatus);
    setResolutionStatus(defaultResolutionStatus);
    setNotes('');
  };

  const onDropDownChange = useCallback(
    (setState) => (event) => {
      setState(event.target.value);
    },
    []
  );
  const authToken = useMemo(() => document.querySelector('meta[name="csrf-token"]').getAttribute('content'), []);
  const resolvePath = useMemo(() => resolveDeficienciesPath({ redirect_path: redirectPath({ screen, screenId }) }), []);

  return (
    <Dialog title={translate('title', { namespace })} className="qmb-dialog" onClose={toggleDialog}>
      <div className="form__field--x-full">
        <span className="qmb-toggle-switch--check">
          <input
            id="resolved"
            type="checkbox"
            checked={isResolved}
            onChange={() => {
              setIsResolved(!isResolved);
            }}
          />
          <label className="switch__visual" htmlFor="resolved">
            &nbsp;
          </label>
          <label className="qmb-label" htmlFor="resolved">
            {translate('resolved', { namespace })}
          </label>
        </span>
      </div>
      <label htmlFor="deficiencyStatus">{translate('deficiency_status', { namespace })}</label>
      <DropDownList
        name="deficiencyStatus"
        id="deficiencyStatus"
        textField="label"
        dataItemKey="value"
        data={deficiencyStatusesForSelect}
        value={deficiencyStatus}
        onChange={onDropDownChange(setDeficiencyStatus)}
        size="large"
      />
      <label htmlFor="resolutionStatus">{translate('resolution_status', { namespace })}</label>
      <DropDownList
        name="resolutionStatus"
        id="resolutionStatus"
        textField="label"
        dataItemKey="value"
        data={resolutionStatusesForSelect}
        value={resolutionStatus}
        onChange={onDropDownChange(setResolutionStatus)}
        size="large"
      />
      <label htmlFor="resolutionStatus">{translate('resolution_notes', { namespace })}</label>
      <TextArea
        defaultValue=""
        rows={8}
        value={notes}
        onChange={(e) => {
          setNotes(e.value);
        }}
      />

      <div className="modal__footer pull-right" style={{ justifyContent: 'space-between' }}>
        <div className="mt-3">
          <form action={resolvePath} method="POST" ref={form}>
            <input name="authenticity_token" type="hidden" value={authToken} />
            <input name="deficiencies[deficiency_id]" type="hidden" value={deficiencyId} />
            <input name="deficiencies[is_resolved]" type="hidden" value={isResolved && 1} />
            <input name="deficiencies[deficiency_resolution_status_id]" type="hidden" value={resolutionStatus.value} />
            <input name="deficiencies[deficiency_status]" type="hidden" value={deficiencyStatus.value} />
            <input name="deficiencies[resolution_notes]" type="hidden" value={notes} />

            <button type="button" className="qmb-button" onClick={clearForm}>
              {I18n.t('generic.cancel')}
            </button>
            <button type="submit" className="qmb-button--submit">
              {I18n.t('generic.update')}
            </button>
          </form>
        </div>
      </div>
    </Dialog>
  );
}

ResolveModal.propTypes = {
  deficiencyId: PropTypes.number.isRequired,
  toggleDialog: PropTypes.func.isRequired
};

export default ResolveModal;
