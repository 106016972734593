import React, { useEffect, useMemo, createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { saveCacheToLocalStorage } from '../../helpers/localStorage';
import { actionTypes, reducer } from './reducer';
import { getInitialData, INITIAL_FILTER_STATE } from './helpers/initialData';
import { updateEvent, fetchEvents } from './helpers/dataAccess';

const CACHE_KEY = 'kendo/calendar';

export const CalendarContext = createContext({
  state: {},
  actions: {}
});

export function CalendarProvider({
  assetsForSelect,
  territoriesForSelect,
  inspectionTypesForSelect,
  inspectionStatusesForSelect,
  visitStatusesForSelect,
  techniciansForSelect,
  permissions,
  timeZone,
  children
}) {
  const [state, dispatch] = useReducer(
    reducer,
    { technicians: techniciansForSelect, cacheKey: CACHE_KEY },
    getInitialData
  );

  const { range, unselectedTechs, statuses, filters, detailed } = state;

  const visitStatusesForHighlight = useMemo(
    () => visitStatusesForSelect.filter((status) => !['deleted_by_technician', 'cancelled'].includes(status.value)),
    []
  );

  useEffect(() => {
    if (!range?.startStr) return;

    fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
  }, [range, filters]);

  useEffect(() => {
    saveCacheToLocalStorage(CACHE_KEY, { filters, detailed, statuses, unselectedTechs, view: range?.view?.type });
  }, [filters, detailed, statuses, unselectedTechs, range?.view?.type]);

  const onEventSave = (formData) => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    updateEvent(formData).then(() => {
      fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
    });
  };

  const onDateRangeChange = (event) => {
    dispatch({ type: actionTypes.DATE_CHANGED, range: event });
  };

  const onEventChange = (data) => {
    const newEvent = {
      ...data.event.extendedProps,
      id: data.event.id,
      title: data.event.title,
      start: data.event.start,
      end: data.event.end
    };

    updateEvent(newEvent)
      .then(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
        fetchEvents({ range, filters }).then(({ data }) => dispatch({ type: actionTypes.DATA_LOADED, data }));
      })
      .catch(({ response }) => {
        data.revert();
        dispatch({ type: actionTypes.NETWORK_REQUEST_FAILED, errorMessage: response.data.error });
      });
  };

  const onToggleStatus = (status) => {
    return () => {
      dispatch({ type: actionTypes.STATUS_TOGGLED, field: status });
    };
  };

  const toggleSelectAllStatuses = () => {
    dispatch({ type: actionTypes.SELECT_ALL_STATUSES, visitStatusesForHighlight, inspectionStatusesForSelect });
  };

  const toggleUnselectAllStatuses = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_STATUSES });
  };

  const toggleSelectAllTechs = () => {
    dispatch({ type: actionTypes.SELECT_ALL_TECHS });
  };

  const toggleUnselectAllTechs = () => {
    dispatch({ type: actionTypes.UNSELECT_ALL_TECHS });
  };

  const onToggleTech = (id) => {
    return (e) => {
      dispatch({ type: actionTypes.TECH_TOGGLED, checked: e.target.checked, id });
    };
  };

  const onChangeView = () => {
    dispatch({ type: actionTypes.DETAILED_TOGGLED });
  };

  const onResetFilters = () => {
    dispatch({
      type: actionTypes.FILTER_CHANGED,
      filters: { active: filters.active, values: INITIAL_FILTER_STATE.values }
    });
  };

  const contextValue = useMemo(() => {
    return {
      dispatch,
      assetsForSelect,
      territoriesForSelect,
      inspectionTypesForSelect,
      inspectionStatusesForSelect,
      visitStatusesForSelect,
      visitStatusesForHighlight,
      techniciansForSelect,
      permissions,
      timeZone,
      state,
      actions: {
        onEventSave,
        onEventChange,
        onDateRangeChange,
        onToggleStatus,
        toggleSelectAllStatuses,
        toggleUnselectAllStatuses,
        toggleSelectAllTechs,
        toggleUnselectAllTechs,
        onToggleTech,
        onChangeView,
        onResetFilters
      }
    };
  }, [state, dispatch]);

  return <CalendarContext.Provider value={contextValue}>{children}</CalendarContext.Provider>;
}

CalendarProvider.propTypes = {
  assetsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionTypesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  inspectionStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  visitStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  techniciansForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  permissions: PropTypes.shape({
    settings: PropTypes.bool,
    techs: PropTypes.bool,
    statuses: PropTypes.bool,
    hasService: PropTypes.bool
  }).isRequired,
  timeZone: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

CalendarProvider.defaultProps = {
  assetsForSelect: [],
  territoriesForSelect: [],
  inspectionTypesForSelect: [],
  inspectionStatusesForSelect: [],
  visitStatusesForSelect: [],
  techniciansForSelect: []
};
