import { translate } from '@utils/i18n';
import { actionTypes } from '../../reducer';

const namespace = 'features.calendar.header.index';

export default ({
  permissions,
  filterActiveState,
  filterValuesState,
  assetsForSelect,
  territoriesForSelect,
  inspectionStatusesForSelect,
  visitStatusesForSelect,
  inspectionTypesForSelect,
  dispatch
}) => {
  return [
    {
      field: 'asset',
      locale: 'asset',
      type: 'treeSelect',
      active: filterActiveState.asset,
      value: filterValuesState.asset,
      optionsForSelect: assetsForSelect,
      onChange: onChangeReactFilter('asset', dispatch),
      onToggle: onToggleFilter('asset', dispatch)
    },
    {
      field: 'building',
      locale: 'building',
      type: 'searchSelect',
      active: filterActiveState.building,
      locked: false,
      value: filterValuesState.building,
      onChange: handleSearchFilterChange('building', dispatch),
      onToggle: onToggleFilter('building', dispatch)
    },
    {
      field: 'technicianTeam',
      locale: 'technician_team',
      type: 'searchSelect',
      active: filterActiveState.technicianTeam,
      locked: false,
      value: filterValuesState.technicianTeam,
      onChange: handleSearchFilterChange('technicianTeam', dispatch),
      onToggle: onToggleFilter('technicianTeam', dispatch)
    },
    {
      field: 'territory',
      locale: 'territory',
      type: 'commonSelect',
      active: filterActiveState.territory,
      locked: false,
      optionsForSelect: territoriesForSelect,
      value: filterValuesState.territory,
      onChange: onChangeReactFilter('territory', dispatch),
      onToggle: onToggleFilter('territory', dispatch)
    },
    {
      field: 'tags',
      locale: 'tags',
      type: 'reactMultiSelect',
      active: filterActiveState.tags,
      locked: false,
      value: filterValuesState.tags,
      onChange: onChangeReactFilter('tags', dispatch),
      onToggle: onToggleFilter('tags', dispatch)
    }
  ].concat(
    visitTypeFilters({
      permissions,
      filterActiveState,
      filterValuesState,
      inspectionStatusesForSelect,
      inspectionTypesForSelect,
      visitStatusesForSelect,
      dispatch
    })
  );
};

const visitTypeFilters = ({
  permissions,
  visitStatusesForSelect,
  filterActiveState,
  filterValuesState,
  inspectionStatusesForSelect,
  inspectionTypesForSelect,
  dispatch
}) => {
  if (permissions.hasService)
    return [
      {
        field: 'type',
        locale: 'visit_type',
        type: 'commonSelect',
        active: filterActiveState.type,
        locked: false,
        optionsForSelect: [
          { value: 'Visit', label: 'Work Order' },
          { value: 'Inspection', label: 'Inspection' }
        ],
        value: filterValuesState.type,
        onChange: onTypeFilterChange(dispatch),
        onToggle: onTypeFilterToggle(dispatch)
      },
      {
        field: 'inspectionStatus',
        locale: 'inspection_status',
        type: 'searchSelect',
        backendField: 'status_code',
        placeholder: 'filters.all_except_cancelled',
        active: filterValuesState.type === 'Inspection',
        locked: true,
        optionsForSelect: inspectionStatusesForSelect,
        value: filterValuesState.inspectionStatus,
        template: (dataItem) => inspectionStatusTemplate(dataItem),
        valueTemplate: (dataItem) => inspectionStatusValueTemplate(dataItem),
        onChange: handleSearchFilterChange('inspectionStatus', dispatch),
        onToggle: onToggleFilter('inspectionStatus', dispatch),
        itemHeight: 40,
        tooltip:
          filterValuesState.type === 'Inspection' ? undefined : translate('inspection_status_tooltip', { namespace })
      },
      {
        field: 'inspectionType',
        locale: 'inspection_type',
        type: 'commonSelect',
        active: filterValuesState.type === 'Inspection',
        locked: true,
        optionsForSelect: inspectionTypesForSelect,
        value: filterValuesState.inspectionType,
        onChange: onChangeReactFilter('inspectionType', dispatch),
        onToggle: onToggleFilter('inspectionType', dispatch),
        tooltip:
          filterValuesState.type === 'Inspection' ? undefined : translate('inspection_type_tooltip', { namespace })
      },
      {
        field: 'visitStatus',
        locale: 'visit_status',
        type: 'commonSelect',
        placeholder: 'filters.all_except_cancelled',
        active: filterValuesState.type === 'Visit',
        locked: true,
        optionsForSelect: visitStatusesForSelect,
        value: filterValuesState.visitStatus,
        onChange: onChangeReactFilter('visitStatus', dispatch),
        onToggle: onToggleFilter('visitStatus', dispatch),
        tooltip: filterValuesState.type === 'Visit' ? undefined : translate('work_order_tooltip', { namespace })
      }
    ];
  return [
    {
      field: 'inspectionStatus',
      locale: 'inspection_status',
      type: 'searchSelect',
      backendField: 'status_code',
      placeholder: 'filters.all_except_cancelled',
      active: filterActiveState.inspectionStatus,
      optionsForSelect: inspectionStatusesForSelect,
      value: filterValuesState.inspectionStatus,
      template: (dataItem) => inspectionStatusTemplate(dataItem),
      valueTemplate: (dataItem) => inspectionStatusValueTemplate(dataItem),
      onChange: handleSearchFilterChange('inspectionStatus', dispatch),
      onToggle: onToggleFilter('inspectionStatus', dispatch),
      itemHeight: 40
    },
    {
      field: 'inspectionType',
      locale: 'inspection_type',
      type: 'commonSelect',
      active: filterActiveState.inspectionType,
      optionsForSelect: inspectionTypesForSelect,
      value: filterValuesState.inspectionType,
      onChange: onChangeReactFilter('inspectionType', dispatch),
      onToggle: onToggleFilter('inspectionType', dispatch)
    }
  ];
};

const handleSearchFilterChange = (fieldName, dispatch) => {
  return (e) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
  };
};

const onChangeReactFilter = (fieldName, dispatch) => {
  return (value) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
  };
};

const onToggleFilter = (fieldName, dispatch) => {
  return () => {
    dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
  };
};

const onTypeFilterChange = (dispatch) => {
  return (value) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value, field: 'type' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
  };
};

const onTypeFilterToggle = (dispatch) => {
  return () => {
    dispatch({ type: actionTypes.FILTER_TOGGLED, field: 'type' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionStatus' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'inspectionType' });
    dispatch({ type: actionTypes.FILTER_CHANGED, value: '', field: 'visitStatus' });
  };
};

const inspectionStatusTemplate = ({ label, icon, color }) => {
  return `<div style="display: flex; align-items: center">
            <span class="qmb-avatar--24--status-icon" style="display: flex; border-radius: 50%; align-items: center; justify-content: center">
              <i class="fa-lg ${icon}" style="color: ${color}" />
            </span>
            <div style="margin-left: 0.8rem;">${label}</div>
          </div>`;
};

const inspectionStatusValueTemplate = ({ label, icon, color }) => {
  return `<span class="qmb-avatar--24--status-icon">
            <i class="fa-lg ${icon}" style="color: ${color}" />
          </span> ${label}`;
};
