import { inspectionStatusPath, mapPath, eventsMapPath } from 'routes';

export const fetchTechnicians = async (filters) => {
  const response = await fetch(`/map/technicians?team_id=${filters?.values?.technicianTeam}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchTechnicianLocations = async(filterValues) => {
  const queryParams = new URLSearchParams();

  if (filterValues && filterValues.technicians) {
    filterValues.technicians.forEach((technicianId) => {
      queryParams.append('technicians[]', technicianId);
    });
  }

  const response = await fetch(`/map/fleet_tracking?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const updateFleetTrackingPassengers = async(techId, vehicleId) => {
  const params = {technician_id: techId, vehicle_id: vehicleId};

  const response = await fetch('/map/update_fleet_tracking_passengers', {
    method: 'post',
    body: JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json'
    }
  });
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
}

export const fetchEvents = async (date) => {
  const response = await fetch(eventsMapPath({date}));
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const saveVisit = async (event) => {

  const requestData = {
    id: event.id,
    type: event.type,
    start_time: event.startTime,
    duration: moment(event.endTime).diff(moment(event.startTime), 'minutes'),
    technician_id: (function(techId) {
      const num = Number(techId); // Attempt to convert to number
      return !isNaN(num) && num > 0 ? num : null; // Check if it's a valid, positive number
    })(event.technicianId),
    status: event.status
  };

  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestData)
  };

  const response = await fetch(mapPath(), requestOptions);
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error);
  }

  return data;
};

export const fetchVisits = async ({ filterValues }) => {
  const queryParams = new URLSearchParams();
  queryParams.append('startDate', moment(filterValues.visitDate.startDate, 'M/D/YYYY').toISOString());
  queryParams.append('endDate', moment(filterValues.visitDate.endDate, 'M/D/YYYY').toISOString());

  if (filterValues.technicians) {
    filterValues.technicians.forEach((technicianId) => {
      queryParams.append('technicians[]', technicianId);
    });
  }

  if (filterValues.tags) {
    filterValues.tags.forEach((tag) => {
      queryParams.append('tags[]', tag);
    });
  }

  // if (filterValues.building) {
  //   filterValues.building.forEach((tag) => {
  //     queryParams.append('building[]', tag);
  //   });
  // }
  queryParams.append('building', filterValues.building);
  queryParams.append('technicianTeam', filterValues.technicianTeam);
  queryParams.append('territory', filterValues.territory);
  queryParams.append('type', filterValues.type);
  queryParams.append('inspectionStatus', filterValues.inspectionStatus);
  queryParams.append('inspectionType', filterValues.inspectionType);
  queryParams.append('workOrderStatus', filterValues.workOrderStatus);
  queryParams.append('asset', filterValues.asset);
  queryParams.append('zipCode', filterValues.zipCode);

  const response = await fetch(`/map/visits?${queryParams.toString()}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  return await response.json();
};

export const fetchVisitStatus = async (status) => {
  const response = await fetch(inspectionStatusPath(status));

  if (!response.ok) {
    throw new Error('Fetching visit status failed.');
  }

  return response.json();
};
