import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import FilterPanel from '@components/FilterPanel';
import FilterToggle from '@components/FilterPanel/nested/FilterToggle';
import { DateTime } from 'luxon';
import Header from './Header';
import {
  actions,
  addError,
  repositionEvent,
  resizeEvent,
  revertAndShiftSaveQueue,
  setEvents,
  setStatus,
  shiftSaveQueue,
  status as schedulerStatuses,
  stopRepositioningEvent,
  stopResizingEvent,
  setFilterValue,
  toggleFilter,
  resetFilters,
  setTechnicians
} from '../Redux/schedulerSlice';
import { fetchEvents, fetchTechnicians, saveVisit } from '../DAL/dataAccess';
import TechnicianCard from './TechnicianCard';
import EventLane from './EventLane';
import CurrentTimeIndicator from './CurrentTimeIndicator';
import { replaceVisit } from '../Redux/visitsSlice';
import { saveCacheToLocalStorage } from '../../../helpers/localStorage';

function Scheduler() {
  const dispatch = useDispatch();
  const timeZone = useSelector((state) => state.settings.timeZone);
  const technicians = useSelector((state) => state.scheduler.technicians);
  const activeDay = useSelector((state) => state.scheduler.activeDay);
  const activeEvent = useSelector((state) => state.scheduler.activeEvent);
  const saveQueue = useSelector((state) => state.scheduler.saveQueue);
  const schedulerStatus = useSelector((state) => state.scheduler.status);
  const activeDayRef = useRef(activeDay);
  const schedulerRef = useRef(null);
  const activeLaneId = useSelector((state) => state.scheduler.activeLaneID);
  const action = useSelector((state) => state.scheduler.action);
  const events = useSelector((state) => state.scheduler.events);
  const holidays = useSelector(
    (state) => state.scheduler.events.filter((x) => x.type === 'Holiday'),
    shallowEqual
  ).filter(
    (x) =>
      moment(activeDay).isSameOrAfter(moment(x.startTime), 'day') &&
      moment(activeDay).isSameOrBefore(moment(x.endTime), 'day')
  );

  const filters = useSelector((state) => state.scheduler.filters);

  useEffect(() => {
    saveCacheToLocalStorage('kendo/map_scheduler/scheduler', { filters });
  }, [filters]);

  const handleSearchFilterChange = (fieldName) => {
    return (e) => {
      dispatch(setFilterValue({ field: fieldName, value: e.sender.value() }));
    };
  };

  const handleFilterToggle = (fieldName) => {
    return () => {
      dispatch(toggleFilter({ field: fieldName }));
    };
  };

  const schedulerFilters = [
    {
      field: 'technicianTeam',
      locale: 'technician_team',
      type: 'searchSelect',
      active: filters.active.technicianTeam,
      value: filters.values.technicianTeam,
      onChange: handleSearchFilterChange('technicianTeam'),
      onToggle: handleFilterToggle('technicianTeam')
    }
  ];

  useEffect(() => {
    dispatch(setStatus(schedulerStatuses.LOADING));
    fetchTechnicians(filters)
      .then((result) => {
        dispatch(setTechnicians(result));
        dispatch(setStatus(schedulerStatuses.READY));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setStatus(schedulerStatuses.ERROR));
        dispatch(addError('Unable to fetch techs.'));
      });
  }, [filters]);

  useEffect(() => {
    if (!activeDay) return;

    dispatch(setStatus(schedulerStatuses.LOADING));
    fetchEvents(activeDay)
      .then((result) => {
        dispatch(setEvents(result));
        dispatch(setStatus(schedulerStatuses.READY));
      })
      .catch((error) => {
        console.error(error);
        dispatch(setStatus(schedulerStatuses.ERROR));
        dispatch(addError('Unable to fetch events.'));
      });
  }, [activeDay]);

  useEffect(() => {
    if (saveQueue.length > 0) {
      const event = saveQueue[0];

      if (
        event.startTime === event.metadata.prevStartTime &&
        event.endTime === event.metadata.prevEndTime &&
        event.technicianId === event.metadata.prevTechnicianId
      ) {
        dispatch(shiftSaveQueue());
        dispatch(setStatus(schedulerStatuses.READY));
        return;
      }

      dispatch(setStatus(schedulerStatuses.SAVING));

      saveVisit(event)
        .then((result) => {
          dispatch(shiftSaveQueue());
          dispatch(setStatus(schedulerStatuses.READY));
        })
        .catch((error) => {
          dispatch(revertAndShiftSaveQueue());
          dispatch(setStatus(schedulerStatuses.ERROR));
          dispatch(addError(error.message));
        });
    }
  }, [saveQueue]);

  useEffect(() => {
    if (schedulerStatus !== schedulerStatuses.REPOSITIONING) return;

    const handleEventRepositionMouseMove = (e) => {
      if (schedulerStatus === schedulerStatuses.REPOSITIONING) {
        const targetTime = DateTime.fromISO(getTimeFromMouseXPosition(e, activeLaneId, activeDay, timeZone)).minus({
          minutes: activeEvent.metadata.minuteOffset
        });
        if (targetTime.minute % 5 !== 0) return; // Snap to 5 minute intervals
        dispatch(repositionEvent({ key: activeEvent.key, targetTime: targetTime.toString() }));
      }
    };

    const handleEventRepositionMouseUp = (e) => {
      e.stopPropagation();
      e.preventDefault();
      dispatch(replaceVisit(activeEvent));
      dispatch(stopRepositioningEvent({ key: activeEvent.key, save: true }));
    };

    document.addEventListener('mousemove', handleEventRepositionMouseMove);
    document.addEventListener('mouseup', handleEventRepositionMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleEventRepositionMouseMove);
      document.removeEventListener('mouseup', handleEventRepositionMouseUp);
    };
  }, [schedulerStatus, activeEvent]);

  useEffect(() => {
    if (schedulerStatus !== schedulerStatuses.RESIZING) return;

    const handleEventResizeMouseMove = (e) => {
      const activeLane = document.getElementById(activeLaneId);
      if (schedulerStatus === schedulerStatuses.RESIZING) {
        const clickX = e.clientX - activeLane.getBoundingClientRect().left;
        const percentage = (clickX / activeLane.clientWidth) * 100;

        // Calculate the time based on the percentage
        const minutes = (percentage / 100) * 1440;
        const hours = Math.floor(minutes / 60);
        const minutesRemainder = Math.floor(minutes % 60);

        if (action === actions.SNAPPING_LEFT || action === actions.SNAPPING_RIGHT) {
          if (minutesRemainder % 15 !== 0) return;
        }

        const newTime = DateTime.fromISO(activeDay, { zone: timeZone }).set({ hour: hours, minute: minutesRemainder });
        dispatch(resizeEvent({ key: activeEvent.key, newTime: newTime.toString() }));
      }
    };

    const handleEventResizeMouseUp = (e) => {
      e.stopPropagation();
      e.preventDefault();

      dispatch(stopResizingEvent(activeEvent.key));
    };

    document.addEventListener('mousemove', handleEventResizeMouseMove);
    document.addEventListener('mouseup', handleEventResizeMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleEventResizeMouseMove);
      document.removeEventListener('mouseup', handleEventResizeMouseUp);
    };
  }, [schedulerStatus]);

  const onResetFilters = () => {
    dispatch(resetFilters());
  };

  const handleResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem('kendo/map_scheduler/scheduler');
    window.location.reload();
  };

  return (
    <div ref={schedulerRef} id="scheduler" className="workspace__schedule">
      <div className="schedule__contain">
        <Header />

        {holidays.map((holiday) => (
          <Holiday key={holiday.key} holiday={holiday} />
        ))}

        <div className="schedule__collection">
          <div className={`schedule__filters${filters.active.technicianTeam ? ' --filtered' : ''}`}>
            {filters.active.technicianTeam && (
              <FilterPanel onResetFilters={onResetFilters} onResetCache={handleResetCache} filters={schedulerFilters} />
            )}
            {!filters.active.technicianTeam && (
              <article className="qmb-filters">
                <fieldset className="filters__group">
                  <div className="filters__actions">
                    <FilterToggle filters={schedulerFilters} />
                  </div>
                </fieldset>
                <fieldset className="filters__group--shortcuts">
                  <button className="qmb-control--sm--manage" onClick={handleFilterToggle('technicianTeam')}>
                    <i className="fa-solid fa-sparkles" />
                    <span>
                      <strong>Suggested:</strong> Technician Team
                    </span>
                  </button>
                </fieldset>
              </article>
            )}
          </div>

          <CurrentTimeIndicator />

          {technicians.map((technician) => (
            <div key={`row-${technician.id}`} className="schedule__row">
              <TechnicianCard technician={technician} />
              <EventLane technician={technician} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

function Holiday({ holiday }) {
  const sidebarWidth = useRef(document.getElementById('global-nav').getBoundingClientRect().width);
  return (
    <div className="schedule__holiday">
      <div className="row__content">
        Company Closed <i className="fa-light fa-umbrella-beach" /> {holiday?.title ? ` ${holiday.title}` : null}
      </div>
    </div>
  );
}

const getTimeFromMouseXPosition = (mouseEvent, laneId, activeDay, timeZone) => {
  const activeLane = document.getElementById(laneId);
  const clickX = mouseEvent.clientX - activeLane.getBoundingClientRect().left;
  const percentage = (clickX / activeLane.clientWidth) * 100;
  const minutes = (percentage / 100) * 1440;
  const hours = Math.floor(minutes / 60);
  const minutesRemainder = Math.floor(minutes % 60);
  const targetTime = DateTime.fromISO(activeDay, { zone: timeZone }).set({ hour: hours, minute: minutesRemainder });
  return targetTime.toString();
};

export default Scheduler;
