import React, { useMemo, useContext } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import adaptivePlugin from '@fullcalendar/adaptive';
import I18n from '@utils/i18n';
import Header from './nested/Header';
import Event from './nested/Event';
import CellHeader from './nested/CellHeader';
import { CalendarContext, CalendarProvider } from './CalendarContext';

function Calendar() {
  const {
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    timeZone,
    state: { events, unselectedTechs, statuses, errorMessage, technicians, view, loading },
    actions: { onEventChange, onDateRangeChange }
  } = useContext(CalendarContext);

  const selectedStatusIds = useMemo(() => Object.keys(statuses).filter((key) => statuses[key]), [statuses]);

  const bgColor = (event) => {
    if (event.type === 'TimeOff') return event.color;

    if (selectedStatusIds.includes(event.groupStatus)) {
      const pool = event.type === 'Visit' ? visitStatusesForSelect : inspectionStatusesForSelect;
      return `${pool.find((status) => status.value === event.status).color}31`;
    }

    return technicians.find((tech) => event.technicianId == tech.id)?.color || '#fff';
  };

  const filteredEvents = useMemo(() => {
    return events
      .filter((event) => event.display === 'background' || !unselectedTechs[event.technicianId])
      .map((event) => {
        const color = bgColor(event);
        return { ...event, backgroundColor: color, borderColor: color };
      });
  }, [unselectedTechs, statuses, events]);

  return (
    <>
      <div>{errorMessage}</div>
      <Header />
      {loading && (
        <div className="qmb-loading--128--page" data-title={I18n.t('generic.loading')}>
          <svg role="img">
            <use href="/map.svg#load-spinner" />
          </svg>
        </div>
      )}
      <FullCalendar
        // TODO: move in env vars
        schedulerLicenseKey="0879361028-fcs-1723636422"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, luxonPlugin, adaptivePlugin]}
        headerToolbar={{
          left: 'title',
          right: 'prev,today,next dayGridMonth,timeGridWeek,timeGridDay'
        }}
        eventContent={EventWrapper}
        dayCellContent={CellHeaderWrapper}
        initialView={view}
        events={filteredEvents}
        datesSet={onDateRangeChange}
        eventDisplay="block"
        editable
        eventChange={onEventChange}
        contentHeight="auto"
        dragScroll="true"
        showNonCurrentDates={false}
        timeZone={timeZone}
        nextDayThreshold="23:59:59"
      />
    </>
  );
}

function EventWrapper(fcProps) {
  return <Event fcProps={fcProps} />;
}
function CellHeaderWrapper(fcProps) {
  return <CellHeader fcProps={fcProps} />;
}

function CalendarWrapper(props) {
  return (
    <CalendarProvider {...props}>
      <Calendar />
    </CalendarProvider>
  );
}

export default CalendarWrapper;
