/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './TextInput.scss';

const TextInput = React.forwardRef(({ id, value, onChange, type, label, ...props }, ref) => {
  return (
    <div className="qmb-input textInputContainer">
      <input id={id} ref={ref} type={type} {...props} />
      <label htmlFor={id} className="qmb-label">
        {label}
      </label>
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string
};

TextInput.defaultProps = {
  onChange: () => {},
  value: '',
  label: '',
  type: 'text'
};
