import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import './Home.scss';

import { inspectionsPath, workOrdersPath, inspectionDashboardAnalyticReportsPath } from 'routes';
import ServiceVisitsDisabled from './nested/ServiceVisitsDisabled';
import Invoices from './nested/Invoices';
import Proposals from './nested/Proposals';
import ProposalsDisabled from './nested/ProposalsDisabled';
import Payments from './nested/Payments';
import PaymentsDisabled from './nested/PaymentsDisabled';
import Deficiencies from './nested/Deficiencies';
import Onboarding from './nested/Onboarding';
import TableModal from '../../../components/TableModal/TableModal';
import useDataTableContext from './context/useDataTableContext';
import getModalInfo from './utils/getModalInfo';
import DashboardSettings from './nested/DashboardSettings/DashboardSettings';
import StatusCounterWidget from './nested/StatusCounterWidget/StatusCounterWidget';
import { DashboardEntityType } from './context/constants';
import { HomeDataTableProvider } from './context/HomeDataTableContext';
import { BaseOtherStatus } from './nested/StatusCounterWidget/constants';

const namespace = 'features.dashboards.home';

function HomeDashboard({
  userName,
  workOrders,
  invoices,
  proposals,
  deficiencies,
  payments,
  currency,
  onboarding,
  month,
  topCounters,
  notificationAmount,
  permissions
}) {
  const {
    state: {
      isModalOpen,
      statusSelected,
      inspectionStatuses,
      workOrderStatuses,
      statusesSelection,
      entityType,
      columnsStructureData,
      tableData,
      isToday,
      loading: loadingModalData
    },
    actions: { closeModal, loadInspections, loadWorkOrders, loadHighPriorities }
  } = useDataTableContext();

  const greeting = useMemo(() => {
    const hour = new Date().getHours();

    if (hour < 12) return translate('greeting.good_morning', { namespace });
    if (hour < 17) return translate('greeting.good_afternoon', { namespace });
    return translate('greeting.good_evening', { namespace });
  }, []);

  const statusInfo = useMemo(() => {
    switch (entityType) {
      case DashboardEntityType.Inspections:
        return [...inspectionStatuses, BaseOtherStatus].find((status) => status.statusCode === statusSelected);
      case DashboardEntityType.WorkOrders:
        return [...workOrderStatuses, BaseOtherStatus].find((status) => status.statusCode === statusSelected);
      case DashboardEntityType.Deficiencies:
        return { name: statusSelected.replace(/_/g, ' ') };
      default:
        return {};
    }
  }, [workOrderStatuses, inspectionStatuses, entityType, statusSelected]);

  const onOpenSidebar = () => {
    const userSideBar = document.getElementById('user-panel');
    if (!userSideBar.className.includes('--active')) {
      userSideBar.className += ' --active';
    }
  };

  const rightActionLink = useMemo(() => {
    switch (entityType) {
      case DashboardEntityType.Inspections:
        return inspectionDashboardAnalyticReportsPath();
      case DashboardEntityType.WorkOrders:
        return null;
      case DashboardEntityType.Invoices:
        return invoices.expandLink;
      case DashboardEntityType.Proposals:
        return proposals.expandLink;
      case DashboardEntityType.Deficiencies:
        return deficiencies.expandLink;
      case DashboardEntityType.Payments:
        return payments.expandLink;
      default:
        return '';
    }
  }, [entityType]);

  const modalInfo = getModalInfo({
    entityType,
    status: statusInfo,
    data: tableData,
    month,
    rangeFromToday: isToday,
    rightActionLink
  });

  return (
    <>
      <div className="dashboard__accent">
        <svg role="img" x="0" y="0" viewBox="0 0 64 64">
          <linearGradient id="dashboard-accent__grad" gradientTransform="rotate(90)">
            <stop offset="0%" className="grad__stop--01" />
            <stop offset="66%" className="grad__stop--02" />
          </linearGradient>
          <polygon points="64,0 0,64 0,0" fill="url(#dashboard-accent__grad)" />
        </svg>
      </div>
      <div className="dashboard__accent--cutoff">
        <svg role="img" x="0" y="0" viewBox="0 0 64 64">
          <linearGradient id="dashboard-accent__grad" gradientTransform="rotate(90)">
            <stop offset="0%" className="grad__stop--01" />
            <stop offset="66%" className="grad__stop--02" />
          </linearGradient>
          <polygon points="64,0 0,64 0,0" fill="url(#dashboard-accent__grad)" />
        </svg>
      </div>
      <div className="dashboard__greet workspace__header--alt--sticky">
        <div className="dashboardGreeting">
          <h1>
            {greeting}
            {userName ? `, ${userName.split(' ')[0]}` : '!'}
          </h1>
          {notificationAmount > 0 && (
            <span role="presentation" className="notificationAlert qmb-control" onClick={onOpenSidebar}>
              <i className="fa-light fa-circle-exclamation" />
              <span>{translate('headers_metrics.notification_alert', { namespace, count: notificationAmount })}</span>
            </span>
          )}
        </div>
        <DashboardSettings permissions={{ ...permissions, service: workOrders.hasService }} />
      </div>

      {onboarding.isEnabled && (
        <>
          <div className="workspace__walkthrough">
            <Onboarding sections={onboarding.sections} skipOnboardingLink={onboarding.skipOnboardingLink} />
          </div>

          <hr className="workspace__split" />
        </>
      )}

      <div className="dashboard__contain">
        <div className="dashboard__widgets">
          <div className="dashboardGeneralMetrics">
            <button type="button" className="qmb-widget--counter" onClick={() => loadInspections({ isToday: true })}>
              <span className="widget__value">{topCounters.inspections}</span>
              <span className="widget__label">{translate('headers_metrics.inspections_today', { namespace })}</span>
            </button>
            {workOrders.hasService && (
              <button
                type="button"
                className="qmb-widget--counter"
                onClick={() => loadWorkOrders({ isToday: true, allVisits: true })}>
                <span className="widget__value">{topCounters.workOrders}</span>
                <span className="widget__label">
                  {translate('headers_metrics.service_visits_today', { namespace })}
                </span>
              </button>
            )}
            <button type="button" className="qmb-widget--counter" onClick={loadHighPriorities}>
              <span className="widget__value">{topCounters.highPriority}</span>
              <span className="widget__label">{translate('headers_metrics.high_priority_items', { namespace })}</span>
            </button>
          </div>
          <StatusCounterWidget
            widgetTitle={translate('inspections', { namespace: 'generic' })}
            statusesInfo={inspectionStatuses}
            statusSelection={statusesSelection.inspections}
            onShowStatusTable={loadInspections}
            month={month}
            chartsLink={inspectionDashboardAnalyticReportsPath()}
            chartsTitle={translate('inspections.expand_title', { namespace })}
            viewAllLink={inspectionsPath()}
          />
          {workOrders.hasService && (
            <StatusCounterWidget
              widgetTitle={translate('service', { namespace: 'generic' })}
              statusesInfo={workOrderStatuses}
              statusSelection={statusesSelection.workOrders}
              onShowStatusTable={loadWorkOrders}
              month={month}
              viewAllLink={workOrdersPath()}
            />
          )}
          {!workOrders.hasService && <ServiceVisitsDisabled supportEmail={workOrders.supportEmail} />}
          {invoices && (
            <Invoices
              timeFrame={invoices.timeFrame}
              amountPaid={invoices.amountPaid}
              amountPaidCount={invoices.amountPaidCount}
              amountDue={invoices.amountDue}
              amountDueCount={invoices.amountDueCount}
              amountTotal={invoices.amountTotal}
              amountTotalCount={invoices.amountTotalCount}
              amountOverDueCount={invoices.amountOverDueCount}
              amountOverDue={invoices.amountOverDue}
              currency={currency}
              expandLink={invoices.expandLink}
              listingLink={invoices.listingLink}
            />
          )}
          {proposals.hasCorrections && (
            <Proposals
              timeFrame={proposals.timeFrame}
              accepted={proposals.accepted}
              sent={proposals.sent}
              declined={proposals.declined}
              created={proposals.created}
              expandLink={proposals.expandLink}
              listingLink={proposals.listingLink}
            />
          )}
          {!proposals.hasCorrections && <ProposalsDisabled supportEmail={proposals.supportEmail} />}
          <Deficiencies
            timeFrame={deficiencies.timeFrame}
            critical={deficiencies.critical}
            impairment={deficiencies.impairment}
            nonCritical={deficiencies.nonCritical}
            expandLink={deficiencies.expandLink}
            listingLink={deficiencies.listingLink}
          />
          {payments.hasPayments && (
            <Payments
              timeFrame={payments.timeFrame}
              totalCount={payments.totalCount}
              totalAmount={payments.totalAmount}
              averageAmount={payments.averageAmount}
              expandLink={payments.expandLink}
            />
          )}
          {!payments.hasPayments && <PaymentsDisabled link={payments.expandLink} />}
        </div>
      </div>
      <TableModal
        isOpen={isModalOpen}
        loading={loadingModalData}
        title={modalInfo.title}
        subTitleView={modalInfo.subTitleView}
        data={tableData}
        columns={columnsStructureData}
        onClose={closeModal}
        timeFrame={modalInfo.timeFrame}
        className="qmb-dialog--modal"
        rightActionButton={modalInfo.rightActionButton}
      />
    </>
  );
}

HomeDashboard.defaultProps = {
  userName: '',
  workOrders: {},
  invoices: {},
  proposals: {},
  deficiencies: {},
  payments: {},
  currency: '$',
  onboarding: {},
  month: '',
  topCounters: {},
  notificationAmount: 0,
  permissions: {}
};

HomeDashboard.propTypes = {
  userName: PropTypes.string,
  workOrders: PropTypes.object,
  invoices: PropTypes.object,
  proposals: PropTypes.object,
  deficiencies: PropTypes.object,
  payments: PropTypes.object,
  currency: PropTypes.string,
  onboarding: PropTypes.object,
  month: PropTypes.string,
  topCounters: PropTypes.object,
  notificationAmount: PropTypes.number,
  permissions: PropTypes.object
};

function HomeDashboardWrapper({ ...props }) {
  return (
    <HomeDataTableProvider workOrderStatuses={props.workOrders.statuses} inspectionStatuses={props.inspectionStatuses}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <HomeDashboard {...props} />
    </HomeDataTableProvider>
  );
}

HomeDashboardWrapper.propTypes = {
  workOrders: PropTypes.object,
  inspectionStatuses: PropTypes.array,
  notificationAmount: PropTypes.number,
  permissions: PropTypes.object
};

HomeDashboardWrapper.defaultProps = {
  workOrders: {},
  inspectionStatuses: [],
  notificationAmount: 0,
  permissions: {}
};

HomeDashboardWrapper.displayName = 'HomeDashboardWrapper';

export default HomeDashboardWrapper;
