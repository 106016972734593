import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs';
import omit from 'lodash/omit';
import I18n, { translate } from '@utils/i18n';
import { IntlProvider, load, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import weekData from 'cldr-core/supplemental/weekData.json';
import esCaGregorian from 'cldr-dates-full/main/es/ca-gregorian.json';
import esDateFields from 'cldr-dates-full/main/es/dateFields.json';
import frCaGregorian from 'cldr-dates-full/main/fr/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr/dateFields.json';
import { Window } from '@progress/kendo-react-dialogs';
import { DateTime } from 'luxon';
import { hideEditModal, setStatus, status as statuses, editValue, editTargetEvent } from './Redux/editModalSlice';

import {
  setStatus as setSchedulerStatus,
  replaceSchedulerEvent,
  status as schedulerStatuses,
  addError
} from './Redux/schedulerSlice';
import { saveVisit } from './DAL/dataAccess';
import { replaceVisit } from './Redux/visitsSlice';
import esMessages from '../../locales/es.json';
import frMessages from '../../locales/fr.json';

load(weekData, esCaGregorian, esDateFields, frCaGregorian, frDateFields); // to i18n DateTimePicker component
loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

const namespace = 'features.map_scheduler';

function EditModal({ selectOptions }) {
  const timeZone = useSelector((state) => state.settings.timeZone);
  const { inspectionStatusesForSelect, visitStatusesForSelect, techniciansForSelect, timeOffTypesForSelect } =
    selectOptions;

  const dispatch = useDispatch();
  const [collapseDetails, setCollapseDetails] = useState(true);
  const modalStatus = useSelector((state) => state.edit.status);
  const selectedVisit = useSelector((state) => state.edit.targetEvent);
  const formData = useSelector((state) => state.edit.formData);
  const durations = useMemo(() =>
    DateTime.fromISO(formData.endTime)
      .diff(DateTime.fromISO(formData.startTime), ['days', 'hours', 'minutes'])
      .toObject()
  );
  const { days: durationDays, hours: durationHours, minutes: durationMinutes } = durations;

  const statusOptions = () => {
    let options;

    if (selectedVisit.type === 'Visit') {
      options = visitStatusesForSelect.map((x) => (
        <option key={`visit_status_${x.value}`} value={x.value}>
          {x.label}
        </option>
      ));
    } else if (selectedVisit.type === 'TimeOff') {
      options = timeOffTypesForSelect.map((x) => (
        <option key={`time_off_type_${x.value}`} value={x.value}>
          {x.label}
        </option>
      ));
      options.unshift(<option key="time_off_type_unknown">Unknown</option>);
    } else {
      options = inspectionStatusesForSelect.map((x) => (
        <option key={`inspection_status_${x.value}`} value={x.value}>
          {x.label}
        </option>
      ));
    }
    return options;
  };

  const technicianOptions = (type) => {
    let technciansFiltered = techniciansForSelect;
    if (type === 'Inspection') {
      technciansFiltered = technciansFiltered.filter((item) => item.type === 'full');
    }

    return technciansFiltered.map((x) => (
      <option key={`technician_${x.value}`} value={x.value}>
        {x.label}
      </option>
    ));
  };

  const globalNav = document.getElementById('global-nav').getBoundingClientRect();
  const globalHeader = document.getElementById('global-header').getBoundingClientRect();

  // whoever gonna get rid of Inspection#status - keep an eye on that place
  const statusIcon = (status) => {
    if (selectedVisit.type === 'Inspection' || selectedVisit.type === 'InspectionVisit') {
      return (
        <span>
          <i className={selectedVisit.statusIcon} style={{ color: selectedVisit.statusColor }} />
          &nbsp;{selectedVisit.humanizedStatus}
        </span>
      );
    }

    switch (status.toLowerCase()) {
      case 'pending':
        return <i className="qmb-status--pending" data-title={I18n.t('enums.visit.visit_statuses.pending')} />;
      case 'scheduled':
        return <i className="qmb-status--scheduled" data-title={I18n.t('enums.visit.visit_statuses.scheduled')} />;
      case 'started':
        return <i className="qmb-status--started" data-title={I18n.t('enums.visit.visit_statuses.started')} />;
      case 'completed':
      case 'complete':
        return <i className="qmb-status--completed" data-title={I18n.t('enums.visit.visit_statuses.completed')} />;
      case 'deleted_by_technician':
      case 'deletedbytechnician':
        return (
          <i className="qmb-status--deleted" data-title={I18n.t('enums.visit.visit_statuses.deleted_by_technician')} />
        );
      case 'waiting_for_review':
        return <i className="qmb-status--review" data-title={I18n.t('enums.visit.visit_statuses.review')} />;
      default:
        return <i className="qmb-status--other" data-title={I18n.t('generic.other')} />;
    }
  };

  const handleSaveClick = () => {
    dispatch(setStatus(statuses.SAVING));

    saveVisit(formData)
      .then((result) => {
        dispatch(editTargetEvent(result));
        dispatch(replaceSchedulerEvent(result));
        dispatch(replaceVisit(formData));
        dispatch(setStatus(statuses.READY));
        toggleDialog();
      })
      .catch((error) => {
        dispatch(addError(error.message));
        dispatch(setSchedulerStatus(schedulerStatuses.ERROR));
        dispatch(setStatus(statuses.READY));
        toggleDialog();
      });
  };

  const handleCommonChange = (field) => {
    return (e) => {
      dispatch(editValue({ field, value: e.target.value }));
    };
  };

  const handleInspectionStatusChange = (e) => {
    const statusOption = e.target.value;

    dispatch(editValue({ field: 'status', value: statusOption.value }));
    dispatch(editValue({ field: 'statusIcon', value: statusOption.icon }));
    dispatch(editValue({ field: 'statusColor', value: statusOption.color }));
    dispatch(editValue({ field: 'humanizedStatus', value: statusOption.label }));
  };

  const handleTechChange = (e) => {
    const value = e.target.value.length ? Number(e.target.value) : e.target.value;
    dispatch(editValue({ field: 'technicianId', value }));
    dispatch(editValue({ field: 'technician', value: techniciansForSelect.find((tech) => tech.id === value) }));
  };

  const handleDateChange = (e) => {
    dispatch(
      editValue({
        field: 'endTime',
        value: DateTime.fromJSDate(e.value)
          .setZone(timeZone)
          .plus({
            days: durationDays,
            hours: durationHours,
            minutes: durationMinutes - timezoneGapMinutes
          })
          .toString()
      })
    );
    dispatch(
      editValue({
        field: 'startTime',
        value: DateTime.fromJSDate(e.value).setZone(timeZone).minus({ minutes: timezoneGapMinutes }).toString()
      })
    );
  };

  const handleDurationChange = (newDuration) => {
    const duration = { days: durationDays, hours: durationHours, minutes: durationMinutes, ...newDuration };

    dispatch(
      editValue({
        field: 'endTime',
        value: DateTime.fromISO(formData.startTime).plus(duration).toString()
      })
    );
  };

  const toggleDetails = () => {
    setCollapseDetails(!collapseDetails);
  };

  const renderAdditionalDetails = (details, nestedLevel) => {
    return Object.keys(omit(details, 'frequency') || {}).map((key) => (
      <div key={key} className="list__item" style={{ marginLeft: `${nestedLevel}rem` }}>
        <span className="item__label">{key}:</span>
        {typeof details[key] === 'object'
          ? renderAdditionalDetails(details[key], nestedLevel + 1)
          : renderDetails(details[key])}
      </div>
    ));
  };

  const renderDetails = (details) => {
    const lines = details.split('\n');
    return (
      <div style={{ display: 'inline-flex', flexDirection: 'column', margin: '0px' }}>
        {lines.map((x) => (x ? <span key={x}>{x}</span> : null))}
      </div>
    );
  };

  const statusItemRender = (element, itemProps) => {
    const { icon, color } = itemProps.dataItem;
    return React.cloneElement(element, element.props, statusRenderTemplate(element, icon, color));
  };

  const statusValueRender = (element, value) => {
    if (!value || !value.icon) {
      return element;
    }

    return React.cloneElement(element, element.props, statusRenderTemplate(element, value.icon, value.color));
  };

  const statusRenderTemplate = (element, icon, color) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span
          className="qmb-avatar--24--status-icon"
          style={{ display: 'flex', borderRadius: '50%', alignItems: 'center', justifyContent: 'center' }}>
          <i className={`fa-lg ${icon}`} style={{ color }} />
        </span>
        <div style={{ marginLeft: '0.8rem' }}>{element.props.children}</div>
      </div>
    );
  };

  const renderStatusSelect = () => {
    if (selectedVisit.type === 'Visit' || selectedVisit.type === 'TimeOff') {
      return (
        <select
          className="tech-select additional-tech"
          value={formData.status}
          onChange={handleCommonChange('status')}
          disabled={formData.readonly}>
          {statusOptions()}
        </select>
      );
    }

    return (
      <DropDownList
        name="status"
        id="status"
        textField="label"
        dataItemKey="value"
        data={inspectionStatusesForSelect}
        itemRender={statusItemRender}
        valueRender={statusValueRender}
        value={{
          label: formData.humanizedStatus,
          value: formData.status,
          icon: formData.statusIcon,
          color: formData.statusColor
        }}
        onChange={handleInspectionStatusChange}
        size="large"
      />
    );
  };

  const toggleDialog = () => {
    dispatch(hideEditModal());
  };

  // Since DatePicker & TimePicker do not support custom timezones the time is artificially changed in form.
  // Here difference between browser's and backend's timezones is added to event's start time
  const timezoneGapMinutes = useMemo(
    () => DateTime.fromISO(formData.startTime, { zone: timeZone }).offset - DateTime.fromISO(formData.startTime).offset,
    [formData.startTime]
  );

  const monkeyPatchedStartTime = useMemo(() => {
    return DateTime.fromISO(formData.startTime).plus({ minutes: timezoneGapMinutes }).toJSDate();
  }, [formData.startTime]);

  return (
    <Window
      title={<Title selectedVisit={selectedVisit} />}
      onClose={toggleDialog}
      minWidth={240}
      initialWidth={480}
      maxWidth={480}
      initialHeight={640}
      minHeight={640}
      initialLeft={globalNav.right + 16}
      initialTop={globalHeader.bottom + 16}
      minimizeButton={() => null}
      maximizeButton={() => null}
      restoreButton={() => null}
      className="qmb-dialog">
      <div className="dialog__section pt-1">
        <ul className="dialog__list--scheduling">
          <li className="dialog__item--title">{selectedVisit.title}</li>
          <li className="dialog__item">{selectedVisit.subtitle}</li>
          <li className="dialog__item">
            {statusIcon(selectedVisit.status)} &ndash;{' '}
            {DateTime.fromISO(selectedVisit.startTime, { zone: timeZone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
          </li>
          <li className="dialog__item--tech">
            <TechnicianInfo technician={selectedVisit.technician} technicianId={selectedVisit.technicianId} />
          </li>
        </ul>
      </div>

      <div className="dialog__section">
        <button onClick={toggleDetails} className="qmb-control--sm--detail-toggle">
          {collapseDetails ? (
            <>
              {translate('edit_modal.expand', { namespace })}{' '}
              <i className="fa-light fa-arrow-up-right-and-arrow-down-left-from-center" />
            </>
          ) : (
            <>
              {translate('edit_modal.collapse', { namespace })}{' '}
              <i className="fa-light fa-arrow-down-left-and-arrow-up-right-to-center" />
            </>
          )}
        </button>

        <div className="dialog__header--section">
          <h2 className="dialog__title">{translate('edit_modal.visit_details', { namespace })}</h2>
        </div>

        <div className={`dialog__list details-box ${collapseDetails ? '--collapsed' : null}`}>
          {renderAdditionalDetails(selectedVisit.details, 0)}
        </div>
      </div>

      <hr className="dialog__split" />

      <div className="dialog__section">
        <div className="dialog__header--section">
          <h2 className="dialog__title">{translate('edit_modal.scheduling_details', { namespace })}</h2>
        </div>
        <fieldset className="form__set--vertical--compact">
          <div className="qmb-select--x-full form__field">
            {renderStatusSelect()}
            <label className="qmb-label">{translate('edit_modal.status', { namespace })}</label>
          </div>
          <div className="qmb-select--x-full form__field">
            <select
              className="tech-select additional-tech"
              value={formData.technicianId}
              onChange={handleTechChange}
              disabled={formData.readonly}>
              <option value="">{I18n.t('generic.unassigned')}</option>
              {technicianOptions(formData.type)}
            </select>
            <label className="qmb-label">{translate('edit_modal.technician', { namespace })}</label>
          </div>

          <fieldset className="form__set--horizontal--compact">
            <LocalizationProvider language={I18n.locale}>
              <IntlProvider locale={I18n.locale}>
                <div className="qmb-input--text--x-half form__field">
                  <DatePicker value={monkeyPatchedStartTime} onChange={handleDateChange} disabled={formData.readonly} />
                  <label className="qmb-label">{translate('edit_modal.scheduled_date', { namespace })}</label>
                </div>
                <div className="qmb-input--text--x-half form__field">
                  <TimePicker value={monkeyPatchedStartTime} onChange={handleDateChange} disabled={formData.readonly} />
                  <label className="qmb-label">{translate('edit_modal.scheduled_time', { namespace })}</label>
                </div>
              </IntlProvider>
            </LocalizationProvider>
          </fieldset>

          <fieldset className="form__set--horizontal--compact">
            <div className="form__field--x-quarter">
              <span className="qmb-label" style={{ width: '100%' }}>
                {translate('edit_modal.duration', { namespace })}:
              </span>
            </div>
            <div className="qmb-input--number--x-quarter duration-field">
              <input
                type="number"
                id="duration_days"
                min="0"
                value={durationDays}
                onChange={(e) => handleDurationChange({ days: e.target.value || 0 })}
                disabled={formData.readonly}
              />
              <label className="qmb-label" htmlFor="duration_days">
                {translate('edit_modal.days', { namespace })}
              </label>
            </div>
            <div className="qmb-input--number--x-quarter duration-field">
              <input
                type="number"
                id="duration_hours"
                min="0"
                max="23"
                value={durationHours}
                onChange={(e) => handleDurationChange({ hours: e.target.value || 0 })}
                disabled={formData.readonly}
              />
              <label className="qmb-label" htmlFor="duration_hours">
                {translate('edit_modal.hours', { namespace })}
              </label>
            </div>
            <div className="qmb-input--number--x-quarter duration-field">
              <input
                type="number"
                id="duration_mins"
                min="0"
                max="59"
                value={durationMinutes}
                onChange={(e) => handleDurationChange({ minutes: e.target.value || 0 })}
                disabled={formData.readonly}
              />
              <label className="qmb-label" htmlFor="duration_mins">
                {translate('edit_modal.minutes', { namespace })}
              </label>
            </div>
          </fieldset>
        </fieldset>

        <div className="modal__footer" style={{ marginTop: '16px' }}>
          {modalStatus === statuses.SAVING ? <Spinner /> : null}
          <button
            type="button"
            className="qmb-button"
            id="charge_customer_cancel_btn"
            onClick={toggleDialog}
            disabled={modalStatus === statuses.SAVING}>
            {translate('edit_modal.cancel', { namespace })}
          </button>
          {!selectedVisit.readonly && (
            <button
              type="button"
              className="qmb-button--submit"
              id="charge_customer_save_btn"
              onClick={handleSaveClick}
              disabled={modalStatus === statuses.SAVING}>
              {translate('edit_modal.save', { namespace })}
            </button>
          )}
        </div>
      </div>
    </Window>
  );
}

function Title({ selectedVisit }) {
  const style = { width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' };
  if (selectedVisit.type === 'Visit')
    return (
      <div style={style}>
        <span>
          <i className="fa-light fa-screwdriver-wrench" />
          &nbsp;
          {translate('edit_modal.visit', { namespace })}
        </span>
        {selectedVisit.url && (
          <a href={selectedVisit.url} target="_blank" className="qmb-control--sm" rel="noreferrer">
            {translate('edit_modal.details', { namespace })}
            <i className="fa-light fa-arrow-up-right-from-square" />
          </a>
        )}
      </div>
    );

  if (selectedVisit.type === 'Inspection' || selectedVisit.type === 'InspectionVisit')
    return (
      <div style={style}>
        <span>
          <i className="fa-light fa-clipboard-check" />
          &nbsp;
          {translate('edit_modal.inspection', { namespace })}
        </span>
        {selectedVisit.url && (
          <a href={selectedVisit.url} target="_blank" className="qmb-control--sm" rel="noreferrer">
            {translate('edit_modal.details', { namespace })}
            <i className="fa-light fa-arrow-up-right-from-square" />
          </a>
        )}
      </div>
    );

  if (selectedVisit.type === 'TimeOff')
    return (
      <div style={style}>
        <span>
          <i className="fa-light fa-calendar-time" />
          &nbsp;
          {translate('edit_modal.time_off', { namespace })}
        </span>
        {selectedVisit.url && (
          <a href={selectedVisit.url} target="_blank" className="qmb-control--sm" rel="noreferrer">
            {translate('edit_modal.details', { namespace })}
            <i className="fa-light fa-arrow-up-right-from-square" />
          </a>
        )}
      </div>
    );

  return (
    <div style={style}>
      <span>
        <i className="fa-light fa-square-question" />
        &nbsp;
        {translate('edit_modal.unknown_visit', { namespace })}
      </span>
      {selectedVisit.url && (
        <a href={selectedVisit.url} target="_blank" className="qmb-control--sm" rel="noreferrer">
          {translate('edit_modal.details', { namespace })}
          <i className="fa-light fa-arrow-up-right-from-square" />
        </a>
      )}
    </div>
  );
}

function Spinner() {
  return (
    <div style={{ flexGrow: 1 }}>
      <div className="load-spinner load-32">
        <svg role="img">
          <use href="/map.svg#load-spinner" />
        </svg>
      </div>
      <span>{I18n.t('generic.saving')}</span>
    </div>
  );
}

function TechnicianInfo({ technician, technicianId }) {
  if (!technicianId) {
    return (
      <span className="option__tech--unassigned">
        <i className="fa-light fa-user-helmet-safety" /> {I18n.t('generic.unassigned')}
      </span>
    );
  }
  if (!technician.photoUrl) {
    return (
      <>
        <span className="qmb-avatar--16">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        {technician.name}
      </>
    );
  }
  return (
    <span className="option__tech">
      <span className="qmb-avatar--16">
        <img
          alt={technician.name[0]}
          style={{ borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }}
          src={technician.photoUrl}
        />
      </span>
      {technician.name}
    </span>
  );
}

EditModal.propTypes = {
  selectOptions: PropTypes.shape({
    inspectionStatusesForSelect: PropTypes.arrayOf(PropTypes.object),
    visitStatusesForSelect: PropTypes.arrayOf(PropTypes.object),
    techniciansForSelect: PropTypes.arrayOf(PropTypes.object),
    timeOffTypesForSelect: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

export default EditModal;
