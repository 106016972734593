import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "@progress/kendo-react-popup";
import { ALL_COLUMNS_DATA as technician } from "../ImportFromQB/constants";
import { hideFleetDropdown, showFleetDropdown, showFleetTrackingModal } from "./Redux/fleetTrackingSlice";

function FleetDropDown() {
  const showFleetDropdownFlag = useSelector((state) => state.fleet.dropdown);
  const drivers = useSelector((state) => state.fleet.techs);
  const dispatch = useDispatch();

  const handleVehicleClick = (vehicle) => {
    dispatch(showFleetTrackingModal(vehicle));
  }

  const handleShowFleetDropdown = () => {
    if(showFleetDropdownFlag) {
      dispatch(hideFleetDropdown());
      return;
    }
    dispatch(showFleetDropdown());
  }

  const handleCloseFleetDropdown = () => {
    dispatch(hideFleetDropdown());
  }

  if(drivers.length === 0) {
    return;
  }

  return(
    <>
      <button id="fleetDropDown" className="btn btn-transparent" style={{backgroundColor: 'transparent'}} onClick={handleShowFleetDropdown}>
        {drivers.map((vehicle) => (
          <TruckSvg key={vehicle.id} vehicle={vehicle} />
        ))}
        &nbsp;&nbsp;<i className={`v-align-middle fa-light fa-chevron-${showFleetDropdownFlag ? 'up' : 'down'}`} />
      </button>
      {showFleetDropdownFlag && <Popup
                                  show
                                  anchor={document.querySelector("#fleetDropDown")}
                                  onClose={handleCloseFleetDropdown}>
        {drivers.map((vehicle) => (
          <span key={vehicle.id}>
            <VehicleInfo vehicle={vehicle} onClickHandler={handleVehicleClick} />
          </span>
        ))}
      </Popup>}
    </>
  );
}

function VehicleInfo({ vehicle, onClickHandler }) {
  return (
    <div style={{ height: "44px", cursor: "pointer", display: "flex", alignItems: "center", margin: "0px", padding: '0 6px', minWidth: 100 }} className="btn-transparent v-align-middle" onClick={() => {onClickHandler(vehicle)}}>
      <span className="qmb-avatar--24 v-align-middle">
        <TruckSvg vehicle={vehicle} />
      </span>
      <span className="v-align-middle">&nbsp;{vehicle.vehicle_name}</span>
    </div>
  );
}

export function TruckSvg({vehicle}) {
  return (
    <svg style={{ enableBackground: "new 0 0 19.93 24"}} className="v-align-middle" xmlns="http://www.w3.org/2000/svg" version="1.1" height="24"
         width="24" x="0" y="0" viewBox="0 0 24 24" xmlSpace="preserve">
          <path style={{ fill: vehicle.color, strokeWidth: '0' }}
                d="M17.19,20.69c-.83,0-1.5-.67-1.5-1.5v-.5h-7v.5c0,.83-.67,1.5-1.5,1.5h-1c-.83,0-1.5-.67-1.5-1.5v-1.26c-.64-.57-1-1.37-1-2.24V6.69c0-1.65,1.35-3,3-3h11c1.65,0,3,1.35,3,3v9c0,.87-.36,1.67-1,2.24v1.26c0,.83-.67,1.5-1.5,1.5h-1ZM17.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM7.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM16.05,10.69l-.41-1.62c-.07-.28-.29-.38-.49-.38h-5.97c-.17,0-.39.1-.46.37l-.41,1.63h7.72Z" />
      <path style={{ fill: '#fff', strokeWidth: '0' }}
            d="M17.69,4.19c1.38,0,2.5,1.12,2.5,2.5v9c0,.82-.39,1.54-1,2v1.5c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1h-8v1c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1.5c-.61-.46-1-1.18-1-2V6.69c0-1.38,1.12-2.5,2.5-2.5h11M7.69,11.19h9l-.56-2.24c-.11-.47-.51-.76-.97-.76h-5.97c-.43,0-.83.28-.94.76l-.56,2.24M17.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M7.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M17.69,3.19H6.69c-1.93,0-3.5,1.57-3.5,3.5v9c0,.93.36,1.8,1,2.45v1.05c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2h6c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2v-1.05c.64-.65,1-1.52,1-2.45V6.69c0-1.93-1.57-3.5-3.5-3.5h0ZM8.97,10.19l.25-1h5.94s.25,1,.25,1h-6.44Z" />
        </svg>
  );
}

export default FleetDropDown;