import produce from 'immer';

export const actionTypes = {
  LOADING_CHANGED: 'LOADING_CHANGED',
  VIEW_CHANGED: 'VIEW_CHANGED',
  DATE_CHANGED: 'DATE_CHANGED',
  DETAILED_TOGGLED: 'DETAILED_TOGGLED',
  FILTER_CHANGED: 'FILTER_CHANGED',
  FILTER_TOGGLED: 'FILTER_TOGGLED',
  TECH_TOGGLED: 'TECH_TOGGLED',
  STATUS_TOGGLED: 'STATUS_TOGGLED',
  DATA_CHANGED: 'DATA_CHANGED',
  DATA_LOADED: 'DATA_LOADED',
  NETWORK_REQUEST_FAILED: 'NETWORK_REQUEST_FAILED',
  UNSELECT_ALL_TECHS: 'UNSELECT_ALL_TECHS',
  SELECT_ALL_TECHS: 'SELECT_ALL_TECHS',
  UNSELECT_ALL_STATUSES: 'UNSELECT_ALL_STATUSES',
  SELECT_ALL_STATUSES: 'SELECT_ALL_STATUSES'
};

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.VIEW_CHANGED:
      draft.view = action.view;
      break;
    case actionTypes.DATE_CHANGED:
      draft.range = action.range;
      draft.loading = true;
      break;
    case actionTypes.DETAILED_TOGGLED:
      draft.detailed = !draft.detailed;
      break;
    case actionTypes.FILTER_CHANGED:
      if (action.filters) {
        draft.filters = action.filters;
      } else {
        if (draft.filters.values[action.field] === action.value) return;
        draft.filters.values[action.field] = action.value;
      }
      draft.loading = true;
      break;
    case actionTypes.FILTER_TOGGLED:
      draft.filters.active[action.field] = !draft.filters.active[action.field];
      draft.filters.values[action.field] = '';
      break;
    case actionTypes.STATUS_TOGGLED:
      draft.statuses[action.field] = !draft.statuses[action.field];
      break;
    case actionTypes.TECH_TOGGLED:
      draft.unselectedTechs[action.id] = !action.checked;
      break;
    case actionTypes.UNSELECT_ALL_TECHS:
      draft.technicians.forEach((tech) => {
        draft.unselectedTechs[tech.id] = true;
      });
      break;
    case actionTypes.SELECT_ALL_TECHS:
      draft.unselectedTechs = {};
      break;
    case actionTypes.UNSELECT_ALL_STATUSES:
      draft.statuses = {};
      break;
    case actionTypes.SELECT_ALL_STATUSES:
      action.inspectionStatusesForSelect.forEach((status) => {
        draft.statuses[`Inspection${status.value}`] = true;
      });

      action.visitStatusesForHighlight.forEach((status) => {
        draft.statuses[`Visit${status.value}`] = true;
      });
      break;
    case actionTypes.DATA_CHANGED: {
      const index = draft.events.findIndex((event) => event.id === action.event.id);

      draft.events[index] = action.newEvent;
      break;
    }
    case actionTypes.NETWORK_REQUEST_FAILED:
      draft.errorMessage = action.errorMessage;
      break;
    case actionTypes.DATA_LOADED:
      draft.events = action.data.events;
      draft.loading = false;
      break;
    case actionTypes.LOADING_CHANGED:
      draft.loading = action.loading;
      break;
    default:
      throw new Error('Reducer - Wrong action type.');
  }
});
