import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import I18n from '@utils/i18n';
import { exportDeficienciesPath } from '../../../routes';

function ExportCsvButton({ deficiencyIds, disabled, queryParams }) {
  const onExport = useCallback(() => {
    if (deficiencyIds) {
      queryParams.append('ids', deficiencyIds);
    } else {
      queryParams.append('all', true);
    }

    window.location = `${exportDeficienciesPath()}?${queryParams}`;
  }, [deficiencyIds]);

  return (
    <button type="button" tabIndex="0" className="qmb-control" disabled={disabled} onClick={onExport}>
      <i className="fa-light fa-file-export" />
      {I18n.t('features.deficiencies_index.export_csv')}
    </button>
  );
}

ExportCsvButton.propTypes = {
  deficiencyIds: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool.isRequired,
  queryParams: PropTypes.object.isRequired
};

ExportCsvButton.defaultProps = {
  deficiencyIds: null
};

export default ExportCsvButton;
