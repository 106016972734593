import React from 'react';
import { isValidDate } from '@fullcalendar/core/internal';
import { translate } from '@utils/i18n';
import { getDateFormat, getDurationFormat, getTimeFormat } from '../../../utils/getDatetimeFormat';
// import LoadableButton from '../../../components/Button/LoadableButton';

const namespace = 'features.work_orders';

const getVisitsColumns = (
  onRequestEdit,
  onRequestDelete,
  visitStatuses,
  visitDeleting,
  subcontractors,
  technicians
) => [
  {
    dataKey: 'assigned',
    width: 180,
    label: translate('assigned_to', { namespace }),
    renderer: ({ item }) => {
      if (item.technician_id || item.subcontractor_id) {
        const techInfo = (item.technician_id ? technicians : subcontractors).find(
          ([, id]) => (item.technician_id || item.subcontractor_id) === id
        );
        if (techInfo) {
          const [name] = techInfo;
          return <span>{name}</span>;
        }
      }
      return translate('unassigned', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'assignedType',
    label: translate('tech_subcontractor', { namespace }),
    renderer: ({ item }) => {
      if (item.technician_id) {
        return <span>{translate('technician', { namespace })}</span>;
      }
      if (item.subcontractor_id) {
        return <span>{translate('subcontractor', { namespace })}</span>;
      }
      return translate('unassigned', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'scheduledDate',
    label: translate('scheduled_date', { namespace }),
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduled_date);
      if (isValidDate(scheduledDate)) {
        return getDateFormat(item?.scheduled_date);
      }
      return translate('n_a', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'scheduledTime',
    label: translate('scheduled_time', { namespace }),
    renderer: ({ item }) => {
      const scheduledDate = new Date(item?.scheduled_date);
      if (isValidDate(scheduledDate)) {
        return getTimeFormat(item?.scheduled_date);
      }
      return translate('n_a', { namespace: 'features.commons' });
    }
  },
  {
    dataKey: 'duration',
    label: translate('duration', { namespace }),
    renderer: ({ item }) => {
      if (!Number(item?.duration)) {
        return <span>{translate('unassigned', { namespace: 'features.commons' })}</span>;
      }
      return <span>{getDurationFormat(item?.duration)}</span>;
    }
  },
  {
    dataKey: 'visit_status',
    label: translate('status', { namespace }),
    renderer: ({ item }) => {
      if (!item?.visit_status) {
        return <span>{translate('unassigned', { namespace: 'features.commons' })}</span>;
      }
      const [label] = visitStatuses.find(([, id]) => id === item.visit_status);
      return <span>{label}</span>;
    }
  },
  {
    dataKey: 'actions',
    width: 100,
    label: '',
    renderer: ({ item }) => {
      return (
        <div className="actionsContainer">
          <button
            type="button"
            className="qmb-control--icon"
            title={translate('edit', { namespace })}
            onClick={() => onRequestEdit(item.id)}>
            <i className="fa-light fa-pencil" />
          </button>
          {/* <LoadableButton
            type="button"
            loading={visitDeleting === item.id}
            className="qmb-control--icon"
            title={translate('delete', { namespace })}
            onClick={() => onRequestDelete(item.id)}>
            <i className="fa-light fa-trash" />
          </LoadableButton> */}
        </div>
      );
    }
  }
];

export default getVisitsColumns;
