import React, { useRef, useState, useCallback, useEffect, useContext } from 'react';
import { Popup } from '@progress/kendo-react-popup';
import { translate } from '@utils/i18n';
import { manageWorkflowStatusPath, techniciansPath, allSettingsPath } from 'routes';
import { CalendarContext } from '../../../CalendarContext';

const namespace = 'features.calendar.header.view';

function View() {
  const {
    state: { detailed },
    actions: { onChangeView },
    permissions
  } = useContext(CalendarContext);
  const anchor = useRef(null);
  const popup = useRef(null);
  const [open, setOpen] = useState(false);

  const printAction = () => window.print();

  const handleBodyClick = useCallback((event) => {
    if (popup.current?._popup?.contains(event.target)) return;
    if (anchor.current.contains(event.target)) return;

    setOpen(false);
  }, []);

  useEffect(() => {
    document.body.addEventListener('click', handleBodyClick);
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onAddEventPopup = useCallback(() => {
    setOpen(!open);
  }, [open]);
  return (
    <>
      <button type="button" className="qmb-control--icon" title="Settings" ref={anchor} onClick={onAddEventPopup}>
        <i className="fa-light fa-gear" />
      </button>
      <Popup anchor={anchor.current} show={open} ref={popup}>
        <div className="qmb-popup--actions">
          <div className="popup__section--group--y-fill">
            <h4 className="popup__title--group">{translate('display', { namespace })}</h4>
            <ul role="presentation">
              <li key="detailed">
                <label className="qmb-radio">
                  <input type="radio" id="display-detailed" onChange={onChangeView} checked={detailed} />
                  <i className="fa-light fa-grid-2" />
                  {translate('detailed', { namespace })}
                </label>
              </li>
              <li key="compact">
                <label className="qmb-radio">
                  <input type="radio" id="display-compact" onChange={onChangeView} checked={!detailed} />
                  <i className="fa-light fa-line-columns" />
                  {translate('compact', { namespace })}
                </label>
              </li>
            </ul>
          </div>

          {(permissions.techs || permissions.statuses) && (
            <>
              <hr className="popup__split" />
              <div className="popup__section--group">
                {permissions.techs && (
                  <a href={techniciansPath()} className="qmb-control">
                    {translate('manage_techs', { namespace })}
                  </a>
                )}
                {permissions.statuses && (
                  <a href={manageWorkflowStatusPath()} className="qmb-control">
                    {translate('manage_workflow_statuses', { namespace })}
                  </a>
                )}
              </div>
            </>
          )}

          {permissions.settings && (
            <>
              <hr className="popup__split" />
              <div className="popup__section--group">
                <a href={allSettingsPath()} className="qmb-control">
                  {translate('settings', { namespace })}
                </a>
                <button type="button" onClick={printAction} className="qmb-control">
                  {translate('print', { namespace })}
                </button>
              </div>
            </>
          )}
        </div>
      </Popup>
    </>
  );
}

export default View;
