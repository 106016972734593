import React, { useState, useContext, useMemo } from 'react';
import { buildingPath } from 'routes';
import PropTypes from 'prop-types';
import I18n, { translate } from '@utils/i18n';
import omit from 'lodash/omit';
import { CalendarContext } from '../../CalendarContext';

const namespace = 'features.calendar.edit_modal.details';

function Details({ event, technician }) {
  const { timeZone, inspectionStatusesForSelect, visitStatusesForSelect } = useContext(CalendarContext);

  const [collapsed, setCollapsed] = useState(true);
  const pool = event.type === 'Visit' ? visitStatusesForSelect : inspectionStatusesForSelect;
  const status = pool.find((stat) => stat.value === event.status);

  const onToggleExpand = () => {
    setCollapsed((old) => !old);
  };

  const formattedStart = useMemo(() => {
    return `${new Date(event.start).toLocaleDateString('en-US')} @ ${new Date(event.start).toLocaleTimeString('en-US', {
      timeZone,
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })}`;
  }, [event.start]);

  const formattedEnd = useMemo(() => {
    return `${new Date(event.end).toLocaleDateString('en-US')} @ ${new Date(event.end).toLocaleTimeString('en-US', {
      timeZone,
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })}`;
  }, [event.end]);

  if (event.type === 'TimeOff')
    return (
      <div className="dialog__section">
        <ul className="dialog__list--scheduling">
          {event.display !== 'background' && (
            <>
              <li className="dialog__item">
                <TechnicianInfo technician={technician} />
              </li>
              <li className="dialog__item">
                {translate('type', { namespace })}: {event.timeOffType}
              </li>
            </>
          )}
          <li className="dialog__item">
            {formattedStart} - {formattedEnd}
          </li>
          <li className="dialog__item">
            {translate('notes', { namespace })}: {event.notes}
          </li>
        </ul>
      </div>
    );

  return (
    <>
      <div className="dialog__section">
        <ul className="dialog__list--scheduling">
          <li className="dialog__item--title">
            <a href={buildingPath(event.buildingId)} target="_blank" rel="noreferrer">
              {event.title}
              <i className="fa-light fa-arrow-up-right-from-square" />
            </a>
          </li>
          <li className="dialog__item">{event.address}</li>
          <li className="dialog__item">
            <span>
              <i style={{ color: status.color }} className={status.icon} />
              {status.label}
            </span>
            &ndash;
            {formattedStart}
          </li>
          <li className="dialog__item--tech">
            <TechnicianInfo technician={technician} />
          </li>
        </ul>
      </div>

      <div className="dialog__section">
        <button type="button" onClick={onToggleExpand} className="qmb-control--sm--detail-toggle">
          {collapsed ? translate('expand', { namespace }) : translate('collapse', { namespace })}
          <i
            className={
              collapsed
                ? 'fa-light fa-arrow-up-right-and-arrow-down-left-from-center'
                : 'fa-light fa-arrow-down-left-and-arrow-up-right-to-center'
            }
          />
        </button>

        <div className="dialog__header--section">
          <h2 className="dialog__title">{translate('visit_details', { namespace })}</h2>
        </div>

        <div className={`dialog__list details-box ${collapsed && '--collapsed'}`}>
          {Object.keys(omit(event.details, 'contact')).map((key) => (
            <div key={key} className="list__item">
              <span className="item__label">{translate(key, { namespace })}:</span>
              <span>{event.details[key]}</span>
            </div>
          ))}
          <div key="contact" className="list__item">
            <span className="item__label">{translate('contact', { namespace })}:</span>
            {Object.keys(event.details.contact).map((key) => (
              <React.Fragment key={key}>
                {event.details.contact[key] && (
                  <div className="list__item" style={{ marginLeft: '1rem' }}>
                    <span className="item__label">{translate(key, { namespace })}:</span>
                    <span>{event.details.contact[key]}</span>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

function TechnicianInfo({ technician }) {
  if (!technician?.id) {
    return (
      <span className="option__tech--unassigned">
        <i className="fa-light fa-user-helmet-safety" /> {I18n.t('generic.unassigned')}
      </span>
    );
  }

  if (!technician.photoUrl) {
    return (
      <>
        <span className="qmb-avatar--16">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        {technician.name}
      </>
    );
  }

  return (
    <span className="option__tech">
      <span className="qmb-avatar--16">
        <img
          alt={technician.name[0]}
          style={{ borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }}
          src={technician.photoUrl}
        />
      </span>
      {technician.name}
    </span>
  );
}

Details.propTypes = {
  event: PropTypes.shape({
    type: PropTypes.string,
    details: PropTypes.object,
    status: PropTypes.string,
    title: PropTypes.string,
    address: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    notes: PropTypes.string,
    timeOffType: PropTypes.string,
    display: PropTypes.string,
    buildingId: PropTypes.number
  }).isRequired,
  technician: PropTypes.shape({
    id: PropTypes.number,
    photoUrl: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

TechnicianInfo.propTypes = {
  technician: PropTypes.shape({
    id: PropTypes.number,
    photoUrl: PropTypes.string,
    color: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

export default Details;
