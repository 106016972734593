import React from 'react';
import { DateTime } from 'luxon';
import I18n from '@utils/i18n';
import { useSelector } from 'react-redux';

function ShallowVisit({ visit, frequency }) {
  const timeZone = useSelector((state) => state.settings.timeZone);

  return (
    <div className="schedule__card--visit">
      <svg className="card__accent" role="img" x="0" y="0" viewBox="0 0 64 64">
        <polygon points="64,0 0,64 0,0 " />
      </svg>
      <div className="card__body">
        <div className="card__text">
          <TypeIcon type={visit.type} />
          <span>{visit.building}</span>
        </div>
        <div className="card__context">
          {frequency && <span>{frequency}</span>}
          <span>
            <i className={visit.statusIcon} style={{ color: visit.statusColor }} />
            &nbsp;
            {DateTime.fromISO(visit.startTime, { zone: timeZone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
          </span>
          <span>
            <TechnicianInfo technician={visit.technician} technicianId={visit.technicianId} />
          </span>
        </div>
      </div>
    </div>
  );
}

function TypeIcon({ type }) {
  switch (type) {
    case 'Visit':
      return <i className="card__visual fa-light fa-screwdriver-wrench" />;
    case 'Inspection':
    case 'InspectionVisit':
      return <i className="card__visual fa-light fa-clipboard-check" />;
    default:
      return <i className="card__visual fa-light fa-square-question" />;
  }
}

function TechnicianInfo({ technician, technicianId }) {
  if (!technicianId || !technician) {
    return (
      <span className="option__tech--unassigned">
        <span className="qmb-avatar--16--no-tech">
          <i className="fa-light fa-user-helmet-safety" />
        </span>
        {I18n.t('generic.unassigned')}
      </span>
    );
  }
  if (!technician.photoUrl) {
    return (
      <span className="option__tech">
        <span className="qmb-avatar--16">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        {technician.name}
      </span>
    );
  }
  return (
    <span className="option__tech">
      <span className="qmb-avatar--16">
        <img
          style={{ borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }}
          src={technician.photoUrl}
        />
      </span>
      {technician.name}
    </span>
  );
}

export default ShallowVisit;
