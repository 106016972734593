import {
  THIS_WEEK,
  LAST_WEEK,
  NEXT_WEEK,
  THIS_MONTH,
  LAST_MONTH,
  NEXT_MONTH,
  THIS_QUARTER,
  LAST_QUARTER,
  NEXT_QUARTER,
  THIS_YEAR,
  LAST_YEAR,
  CUSTOM_RANGE,
  DATE_FORMAT
} from '@constants/Kendo/rangeOptions';

const FIELD_TYPE = 'dateRange';

// Add moment.js from Window
const { moment } = window;

export const getDatesForRangeType = (rangeType, startDate, endDate) => {
  switch (rangeType) {
    case THIS_WEEK:
      return [moment().startOf('week'), moment().endOf('week')].map((date) => date.format(DATE_FORMAT));
    case LAST_WEEK:
      return [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')].map((date) =>
        date.format(DATE_FORMAT)
      );
    case NEXT_WEEK:
      return [moment().add(1, 'weeks').startOf('week'), moment().add(1, 'weeks').endOf('week')].map((date) =>
          date.format(DATE_FORMAT)
      );
    case THIS_MONTH:
      return [moment().startOf('month'), moment().endOf('month')].map((date) => date.format(DATE_FORMAT));
    case LAST_MONTH:
      return [moment().subtract(1, 'months').startOf('month'), moment().subtract(1, 'months').endOf('month')].map(
        (date) => date.format(DATE_FORMAT)
      );
    case NEXT_MONTH:
      return [moment().add(1, 'months').startOf('month'), moment().add(1, 'months').endOf('month')].map(
          (date) => date.format(DATE_FORMAT)
      );
    case THIS_QUARTER:
      return [moment().startOf('quarter'), moment().endOf('quarter')].map((date) => date.format(DATE_FORMAT));
    case LAST_QUARTER:
      return [
        moment().subtract(1, 'quarters').startOf('quarter'),
        moment().subtract(1, 'quarters').endOf('quarter')
      ].map((date) => date.format(DATE_FORMAT));
    case NEXT_QUARTER:
      return [
        moment().add(1, 'quarters').startOf('quarter'),
        moment().add(1, 'quarters').endOf('quarter')
      ].map((date) => date.format(DATE_FORMAT));
    case THIS_YEAR:
      return [moment().startOf('year'), moment().endOf('year')].map((date) => date.format(DATE_FORMAT));
    case LAST_YEAR:
      return [moment().subtract(1, 'years').startOf('year'), moment().subtract(1, 'years').endOf('year')].map((date) =>
        date.format(DATE_FORMAT)
      );
    default:
      return [startDate, endDate];
  }
};

export const actionTypes = { DATE_RANGE_FILTER_CHANGED: 'DATE_RANGE_FILTER_CHANGED' };

export const isDateRangeStateField = (fieldState) => {
  return typeof fieldState === 'object' && fieldState !== null  && fieldState.fieldType === FIELD_TYPE;
};

export const buildInitialState = (fieldName, initialRange, active = false) => {
  if (initialRange){
    const dates = getDatesForRangeType(initialRange, '', '');

    return {
      [fieldName]: {
        rangeType: initialRange,
        startDate: dates[0],
        endDate: dates[1],
        fieldType: FIELD_TYPE,
        active: active
      }
    };
  }

  return {
    [fieldName]: {
      rangeType: CUSTOM_RANGE,
      startDate: '',
      endDate: '',
      fieldType: FIELD_TYPE,
      active: active
    }
  };
};

export const reducerFunction = (draft, action) => {
  switch (action.type) {
    case actionTypes.DATE_RANGE_FILTER_CHANGED:
      if (action.dateField === 'rangeType') {
        draft.filters[action.field].rangeType = action.value;
        [draft.filters[action.field].startDate, draft.filters[action.field].endDate] = getDatesForRangeType(
          action.value,
          draft.filters[action.field].startDate,
          draft.filters[action.field].endDate
        );
      } else {
        draft.filters[action.field].rangeType = CUSTOM_RANGE;
        draft.filters[action.field][action.dateField] = action.value;
      }
      break;
  }
};

export const reducerValuesFunction = (draft, action) => {
  if (!isDateRangeStateField(draft.filters.values[action.field])) {
    draft.filters.values = { ...draft.filters.values, ...buildInitialState(action.field) };
  }

  switch (action.type) {
    case actionTypes.DATE_RANGE_FILTER_CHANGED:
      if (action.dateField === 'rangeType') {
        draft.filters.values[action.field].rangeType = action.value;
        [draft.filters.values[action.field].startDate, draft.filters.values[action.field].endDate] = getDatesForRangeType(
          action.value,
          draft.filters.values[action.field].startDate,
          draft.filters.values[action.field].endDate
        );
      } else {
        draft.filters.values[action.field].rangeType = CUSTOM_RANGE;
        draft.filters.values[action.field][action.dateField] = action.value;
      }
      break;
  }
};
