import React, { useEffect, useCallback, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { Grid, GridToolbar, GridColumn as Column } from '@progress/kendo-react-grid';
import FilterPanel from '@components/FilterPanel';
import I18n from '@utils/i18n';
import { LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import api from '@utils/axios';
import { saveCacheToLocalStorage } from '../../helpers/localStorage';
import { getInitialData, getInitialFilters } from './helpers/initialData';
import getFiltersList from './helpers/filtersList';
import ColumnMenu from './nested/ColumnMenu';
import ColumnMenuContext from './nested/ColumnMenuContext';
import MassActions from './nested/MassActions';
import kendoDatatableParams from '../../helpers/kendoDatatableParams';
import esMessages from '../../locales/es.json';
import frMessages from '../../locales/fr.json';
import './index.css';
import { datatableDeficienciesPath } from '../../routes';
import { actionTypes, reducer } from './reducer';
import { ALL_COLUMNS, ALL_COLUMNS_DATA, CACHE_KEY } from './constants';
import CustomScrollbar from '../../components/CustomScrollbar/CustomScrollbar';

loadMessages(esMessages, 'es');
loadMessages(frMessages, 'fr');

function DeficienciesIndex({
  territoriesForSelect,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  salesPeopleForSelect,
  assetsForSelect,
  screen,
  screenId,
  cacheKey
}) {
  const initialData = useMemo(() => getInitialData(cacheKey, screen, screenId), []);
  const [state, dispatch] = useReducer(reducer, {
    data: [],
    totalCount: 0,
    selectAll: false,
    loading: true,
    ...initialData
  });

  const { page, sort, filters, columns, data, totalCount, selectAll, loading } = state;

  useEffect(() => {
    saveCacheToLocalStorage(cacheKey, { page, sort, filters, columns });
  }, [page, sort, filters, columns]);

  useEffect(() => {
    dispatch({ type: actionTypes.LOADING_CHANGED, loading: true });
    api
      .get(datatableDeficienciesPath(), { params: kendoDatatableParams({ page, sort, filters }) })
      .then(({ data: deficienciesData }) => dispatch({ type: actionTypes.DATA_LOADED, data: deficienciesData }))
      .finally(() => {
        dispatch({ type: actionTypes.LOADING_CHANGED, loading: false });
      });
  }, [page]);

  const handlePageSizeChange = useCallback((newValue) => {
    debouncedSetPageSizeValue(newValue);
  }, []);

  const debouncedSetPageSizeValue = debounce((newValue) => {
    dispatch({ type: actionTypes.PAGE_CHANGED, page: newValue });
  }, 300);

  const filtersList = useMemo(
    () =>
      getFiltersList({
        filters,
        dispatch,
        territoriesForSelect,
        resolutionStatusesForSelect,
        deficiencyStatusesForSelect,
        salesPeopleForSelect,
        assetsForSelect,
        screen
      }),
    [filters]
  );

  const onColumnsChange = useCallback(
    (cols) => dispatch({ type: actionTypes.COLUMNS_CHANGED, columns: cols.map((col) => col.id) }),
    []
  );

  const onResetFilters = useCallback(() => {
    dispatch({ type: actionTypes.FILTER_CHANGED, filters: getInitialFilters(screen, screenId) });
  }, []);

  const onResetCache = () => {
    // eslint-disable-next-line no-alert
    if (!window.confirm(I18n.t('generic.are_you_sure'))) return;
    localStorage.removeItem(cacheKey);
    window.location.reload();
  };

  const onSelectionChange = useCallback((event) => {
    dispatch({ type: actionTypes.SELECTION_CHANGED, id: event.dataItem.id });
  }, []);

  const onHeaderSelectionChange = useCallback((event) => {
    dispatch({ type: actionTypes.HEADER_SELECTION_CHANGED, checked: event.syntheticEvent.target.checked });
  }, []);

  const onSelectAll = useCallback(() => {
    dispatch({ type: actionTypes.ALL_SELECTED });
  }, []);

  const headerSelectionValue = selectAll || (totalCount > 0 && data.every((inspection) => inspection.selected));

  const allColumnsWrapped = useMemo(() => ALL_COLUMNS.map((column) => ALL_COLUMNS_DATA[column]), []);
  const columnsWrapped = useMemo(() => columns.map((column) => ALL_COLUMNS_DATA[column]), [columns]);
  const columnContextValue = useMemo(
    () => ({
      onColumnsChange,
      columnsState: columnsWrapped,
      columns: allColumnsWrapped,
      resolutionStatusesForSelect,
      deficiencyStatusesForSelect,
      screen,
      screenId
    }),
    [
      onColumnsChange,
      columnsWrapped,
      allColumnsWrapped,
      resolutionStatusesForSelect,
      deficiencyStatusesForSelect,
      screen,
      screenId
    ]
  );

  return (
    <LocalizationProvider language={I18n.locale}>
      <ColumnMenuContext.Provider value={columnContextValue}>
        <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filtersList} />
        {loading && (
          <div className="workspace__section">
            <div className="qmb-loading--96" data-title={I18n.t('generic.loading')}>
              <svg role="img">
                <use href="/map.svg#load-spinner" />
              </svg>
            </div>
          </div>
        )}
        {!loading && (
          <CustomScrollbar>
            <Grid
              data={data}
              total={totalCount}
              skip={page.skip}
              take={page.take}
              onPageChange={(event) => {
                handlePageSizeChange(event.page);
              }}
              pageable={{
                type: 'input',
                pageSizes: [25, 50, 100],
                pageSizeValue: page.take
              }}
              sortable
              sort={sort}
              onSortChange={(event) => {
                dispatch({ type: actionTypes.SORT_CHANGED, sort: event.sort });
              }}
              dataItemKey="id"
              selectedField="selected"
              onSelectionChange={onSelectionChange}
              onHeaderSelectionChange={onHeaderSelectionChange}
              className="qmb-grid--01">
              <GridToolbar>
                <MassActions state={state} onSelectAll={onSelectAll} queryParams={kendoDatatableParams({ filters })} />
              </GridToolbar>
              {columnsWrapped.map((column) => (
                <Column
                  key={column.id}
                  field={column.field}
                  title={column.title}
                  columnMenu={ColumnMenu}
                  cell={column.cell}
                  sortable={column.sortable}
                  headerSelectionValue={headerSelectionValue}
                  className={column.className}
                  width={column.width}
                  headerClassName={column.headerClassName}
                />
              ))}
            </Grid>
          </CustomScrollbar>
        )}
      </ColumnMenuContext.Provider>
    </LocalizationProvider>
  );
}

DeficienciesIndex.propTypes = {
  territoriesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  resolutionStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  deficiencyStatusesForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  salesPeopleForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any
    })
  ),
  assetsForSelect: PropTypes.arrayOf(PropTypes.object),
  screen: PropTypes.string,
  screenId: PropTypes.number,
  cacheKey: PropTypes.string
};

DeficienciesIndex.defaultProps = {
  territoriesForSelect: [],
  resolutionStatusesForSelect: [],
  deficiencyStatusesForSelect: [],
  salesPeopleForSelect: [],
  assetsForSelect: [],
  screen: null,
  screenId: null,
  cacheKey: CACHE_KEY
};

export default DeficienciesIndex;
