import React, { useMemo, useContext } from 'react';
import FilterPanel from '@components/FilterPanel';
import I18n from '@utils/i18n';
import Technicians from './nested/Technicians';
import Statuses from './nested/Statuses';
import View from './nested/View';
import CreateEvent from '../CreateEvent';
import filtersList from './filtersList';
import { CalendarContext } from '../../CalendarContext';

const kendoCacheKey = 'kendo/calendar';

function Header() {
  const {
    dispatch,
    assetsForSelect,
    territoriesForSelect,
    inspectionTypesForSelect,
    inspectionStatusesForSelect,
    visitStatusesForSelect,
    permissions,
    actions: { onResetFilters },
    state: {
      filters: { active: filterActiveState, values: filterValuesState },
      loading
    }
  } = useContext(CalendarContext);

  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  const filters = useMemo(
    () =>
      filtersList({
        permissions,
        filterActiveState,
        filterValuesState,
        assetsForSelect,
        territoriesForSelect,
        inspectionStatusesForSelect,
        inspectionTypesForSelect,
        visitStatusesForSelect,
        dispatch
      }),
    [filterActiveState, filterValuesState]
  );

  const style = loading ? { pointerEvents: 'none', opacity: '0.5', top: '0' } : { top: '0' };

  return (
    <>
      <FilterPanel onResetFilters={onResetFilters} onResetCache={onResetCache} filters={filters} />
      <div className="qmb-actions--sticky" style={style}>
        <div className="actions__group--flex">
          <CreateEvent withTitle />
          <Technicians />
          <Statuses />
        </div>
        <div className="actions__group">
          <View />
        </div>
      </div>
    </>
  );
}

export default Header;
