import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {startDragging, types} from "./Redux/draggingSlice";
import {showEditModal} from "./Redux/editModalSlice";
import { showFleetTrackingModal, updateDrivers } from "./Redux/fleetTrackingSlice";
import { fetchTechnicianLocations } from "./DAL/dataAccess";

function GoogleMap({configuration}){

    const dispatch = useDispatch();
    const mapRef = useRef(null);
    const mapObjRef = useRef(null);
    const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);
    const visits = useSelector(state => state.map.visits);
    const schedulerEvents = useSelector(state => state.scheduler.events)
    const visitsRef = useRef(visits)
    const existingMarkers = useRef([])
    const targetVisit = useSelector(state => state.edit.targetEvent)

    const technicians = useSelector(state => state.scheduler.technicians)
    const isFirstRender = useRef(true);

    const technicianMarkers = useRef([]);
    const filters = useSelector(state => state.map.filters);
    const filterRef = useRef(filters);
    const fleetTrackingActive = useSelector(state => state.fleet.active);
    const drivers = useSelector(state => state.fleet.techs);

    useEffect(() => {
        filterRef.current = filters;
    }, [filters]);

    useEffect(() => {
        const loadJS = (src) => {
            const ref = window.document.getElementsByTagName("script")[0];
            const script = window.document.createElement("script");
            script.src = src;
            script.async = true;
            ref.parentNode.insertBefore(script, ref);
        };

        const initMap = async () => {
            if (typeof google === "object" && typeof google.maps === "object") {
                setGoogleMapsLoaded(true);
            } else {
                loadJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyDCeC2og9B6d4Uo5koNx_RZjVVl0uiTqho&callback=initMap');
                window.initMap = () => setGoogleMapsLoaded(true);
            }
        };

        initMap();
    }, []);


    useEffect(() => {
        if (googleMapsLoaded) {
            const map = new google.maps.Map(mapRef.current, {
                center: { lat: configuration.centerLat, lng: configuration.centerLng },
                zoom: 10,
                mapId: '6b511e86ce5460cb',
            });
            mapObjRef.current = map

            loadCenterMarker(configuration.centerLat, configuration.centerLng)
            loadVisitsAsMarkers(visits)
        }
    }, [googleMapsLoaded]);


    useEffect(()=>{
        if (googleMapsLoaded){

            let mergedVisits = []
            let encounteredKeys = []

            for (let i = 0; i < visits.length; i++){
                mergedVisits.push(visits[i]);
                encounteredKeys.push(visits[i].key)
            }


            for (let i = 0; i < schedulerEvents.length; i++){
                const event = schedulerEvents[i];
                if (encounteredKeys.includes(event.key)){
                    continue;
                }


                if (event.technicianId === null || !technicians.find(t => t.id === event.technicianId)?.showOnMap){
                    continue;
                }


                mergedVisits.push(event);
                encounteredKeys.push(event.key);
            }
            loadVisitsAsMarkers(mergedVisits)

            // if (isFirstRender.current && mergedVisits.length > 0){
            //     const firstVisit = mergedVisits.find(x => x.lat && x.lng);
            //
            //     if (firstVisit){
            //         mapObjRef.current.setOptions({center: { lat: firstVisit.lat, lng: firstVisit.lng }});
            //         mapObjRef.current.panTo({lat: firstVisit.lat, lng: firstVisit.lng});
            //     }
            //     isFirstRender.current = false;
            // }

            loadTechniciansAsMarkers(drivers);

            // 2min interval
            const intervalId = setInterval(() => {
                refreshTechnicianLocations()
            }, 2 * 60 * 1000);

            return () => clearInterval(intervalId)
        }
    }, [visits, schedulerEvents, googleMapsLoaded, technicians])


    useEffect(() => {
        if (targetVisit){
            const marker = existingMarkers.current.find(x => x.visit.key === targetVisit.key)?.marker
            if (marker){
                mapObjRef.current.panTo({lat: marker.position.lat, lng: marker.position.lng});
                focusMarkers(targetVisit.key)
            } else {
                focusMarkers()
            }
        } else {
            focusMarkers()
        }
    }, [targetVisit])

    const focusMarkers = (key) => {
        existingMarkers.current.forEach(x => {
           if (x.marker){
               x.marker.targetElement.style.opacity = key ? (x.visit.key !== key ? '0.3' : '1') : '1';
           }
        });
    }



    const HandleMouseDown = (e, marker, visit) => {
        mapObjRef.current.setOptions({draggable: false, zoomControl: false, scrollwheel: false, disableDoubleClickZoom: true});
        dispatch(startDragging({x: e.clientX, y: e.clientY, data: visit, type: types.VISIT}))
        document.addEventListener('mouseup', HandleMouseUp, {once: true})
    }

    const HandleMouseUp = (e) => {
        mapObjRef.current.setOptions({draggable: true, zoomControl: true, scrollwheel: true, disableDoubleClickZoom: true});
    }


    const HandleMouseClick = (e, marker, visit) => {
        dispatch(showEditModal(visit))
    }

    const HandleDriverMouseClick = (e, tech) => {
        dispatch(showFleetTrackingModal(tech));
    }

    const loadCenterMarker = async (lat, lng) => {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

        const parser = new DOMParser();
        const centerSVGString = `
                <svg version="1.1" height="32" width="32" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 32 32" style="enable-background:new 0 0 32 32" xml:space="preserve">
    <path d="m6.45 4.15-.29-.46c-.26-.42-.71-.67-1.2-.67H1.31v5.07h4.78l.29.47c.02.02.05.04.07.06V4.15z" style="fill:#f1604c"/>
    <path d="M31.19 31h-1.5V11.72c0-.83-.67-1.5-1.5-1.5H12.02v.99H7.59c-.91 0-1.75-.37-2.38-.99h-1.4c-.83 0-1.5.67-1.5 1.5V31h-1V9.08h4.23a2.42 2.42 0 0 0 2.05 1.14h3.43V3.16H7a2.398 2.398 0 0 0-2.05-1.14H1.31V1.01c0-.28-.22-.5-.5-.5s-.5.22-.5.5V31.5c0 .04.01.08.02.12.06.22.25.38.48.38h30.38c.28 0 .5-.22.5-.5s-.22-.5-.5-.5zM4.95 3.02c.49 0 .94.25 1.2.67l.29.47h3.57v5.07H7.59c-.49 0-.94-.25-1.2-.67l-.3-.48H1.31V3.02h3.64z" style="fill:#010101;stroke:#010101;stroke-width:2;stroke-miterlimit:10;stroke-opacity:.06"/>
    <path style="fill:#f0eff4" class="st2" d="M23.5 22.99h-15c-.28 0-.5-.22-.5-.5V20.1c0-.28.22-.5.5-.5h15c.28 0 .5.22.5.5v2.39c0 .27-.22.5-.5.5zM22.97 24.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 26.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 28.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM22.97 30.99H9.03c-.29 0-.53-.22-.53-.5s.24-.5.53-.5h13.95c.29 0 .53.22.53.5s-.25.5-.54.5zM23 14.22v1.39h-5.5v-1.39H23m.5-1H17c-.28 0-.5.22-.5.5v2.39c0 .28.22.5.5.5h6.5c.28 0 .5-.22.5-.5v-2.39c0-.28-.22-.5-.5-.5zM14.5 14.22v1.39H9v-1.39h5.5m.5-1H8.5c-.28 0-.5.22-.5.5v2.39c0 .28.22.5.5.5H15c.28 0 .5-.22.5-.5v-2.39c0-.28-.22-.5-.5-.5z"/>
    <path style="fill:#c0476b" class="st3" d="M5.89 3.38s-.01-.01-.02-.01c.01 0 .01.01.02.01zM7 4.16h-.55v-.01 4.47c.27.38.68.6 1.14.6h2.43V4.16H7z"/>
</svg>`
        const centerSVG = parser.parseFromString(
            centerSVGString,
            "image/svg+xml",
        ).documentElement;


        const marker = new AdvancedMarkerElement({
            map: mapObjRef.current,
            position: { lat: lat, lng: lng },
            content: centerSVG
        });
        
    }

    const loadVisitsAsMarkers = async (newVisits) => {
        if (newVisits !== visitsRef.current){
            const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

            for(let i = 0; i < existingMarkers.current.length; i++){
                existingMarkers.current[i].marker.setMap(null)
            }

            let newMarkers = []
            for (let i = 0; i < newVisits.length; i++){
                const visit = newVisits[i];

                if (visit.lat && visit.lng){

                    const min = -0.00004;
                    const max = 0.00004;

                    const jitterLat = Math.random() * (max - min) + min;
                    const jitterLng = Math.random() * (max - min) + min;

                    let pin = new PinElement(pinContent(visit))
                    const parser = new DOMParser();
                    const pinSvgString = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="32" viewBox="0 0 512 640" fill="none"><path d="M256,0C114.62,0,0,114.62,0,256c0,256.15,256,384,256,384s256-127.85,256-384C512,114.62,397.38,0,256,0z"/></svg>';
                    const pinSvg = parser.parseFromString(
                        pinSvgString,
                        "image/svg+xml",
                    ).documentElement;

                    let marker = new AdvancedMarkerElement({
                        id: `${visit.type}_${visit.id}`,
                        position: { lat: visit.lat + jitterLat, lng: visit.lng + jitterLng},
                        map: mapObjRef.current,
                        title: visit.building,
                        content: pin.element,
                        draggable: false,
                    })

                    marker.addListener('click', (e) => {HandleMouseClick(e, marker, visit)})
                    marker.content.addEventListener('mousedown', (e) => {HandleMouseDown(e, marker, visit)})
                    newMarkers.push({visit: visit, marker: marker})

                }

            }

            existingMarkers.current = newMarkers
        }
    }

    const refreshTechnicianLocations = async () => {
        if(!fleetTrackingActive) {
            return;
        }
        const results = await fetchTechnicianLocations(filterRef?.current?.values);
        dispatch(updateDrivers(results));
        if(!fleetTrackingActive) {
            return;
        }
        loadTechniciansAsMarkers(drivers);
    }

    const loadTechniciansAsMarkers = async (techs) => {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

        for(let i = 0; i < technicianMarkers.current.length; i++){
            technicianMarkers.current[i].marker.setMap(null)
        }

        let newMarkers = []
        for (let i = 0; i < techs.length; i++){
            const tech = techs[i];

            if (tech.lat && tech.lng){

                const min = -0.00004;
                const max = 0.00004;

                const jitterLat = Math.random() * (max - min) + min;
                const jitterLng = Math.random() * (max - min) + min;

                const pin = pinContentTech(tech);

                let marker = new AdvancedMarkerElement({
                    id: `${tech.name}_${tech.id}`,
                    position: { lat: tech.lat + jitterLat, lng: tech.lng + jitterLng},
                    map: mapObjRef.current,
                    title: `${tech.vehicle_name} Status: ${tech.vehicle_status}`,
                    content: pin,
                    draggable: false,
                });

                marker.addListener('click', (e) => {HandleDriverMouseClick(e, tech)})

                newMarkers.push({tech: tech, marker: marker})

            }

        }

        technicianMarkers.current = newMarkers
    }

    const customPin = (visit) => {
        const parser = new DOMParser();
        let pinSVGString = ``;


        if (visit.technicianId){

            if (visit.technician.photoUrl){

                pinSVGString =
                `<svg class="qmb-avatar" version="1.1" height="24" width="24" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 19.93 24" style="enable-background:new 0 0 19.93 24;" xml:space="preserve">
                    <path style="fill: ${visit.technician.color};" class="st0" d="m9.96 24-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06 3.88-3.88 10.18-3.88 14.06 0 3.88 3.88 3.88 10.18 0 14.06L9.96 24z"/>
                    <path style="fill: ${visit.technician.color}; opacity:.06" class="st0" d="M9.96 0c2.54 0 5.09.97 7.03 2.91 3.88 3.88 3.88 10.18 0 14.06L9.96 24l-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06A9.92 9.92 0 0 1 9.96 0m0-2C6.77-2 3.77-.76 1.52 1.5c-4.66 4.66-4.66 12.23 0 16.89l7.03 7.03 1.41 1.41 1.41-1.41 7.03-7.03c4.66-4.66 4.66-12.23 0-16.89C16.15-.76 13.15-2 9.96-2z" />
            
                    <image 
                        xmlns="http://www.w3.org/2000/svg" 
                        x="50%" 
                        y="50%" 
                        width="16" 
                        height="16" 
                        transform="translate(-8,-10)" 
                        style="border-radius: 50%" 
                        href="${visit.technician.photoUrl}" 
                        clip-path="inset(0% round 15px)"/>

                </svg>`


                pinSVGString = `
<?xml version="1.0" encoding="UTF-8"?>
<svg xmlns="http://www.w3.org/2000/svg" class="qmb-avatar" version="1.1" height="24" width="24" x="0" y="0" viewBox="0 0 19.93 24" xml:space="preserve">
                    <path style="fill: #7bd148;" d="m9.96 24-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06 3.88-3.88 10.18-3.88 14.06 0 3.88 3.88 3.88 10.18 0 14.06L9.96 24z"></path>
                    <path style="fill: #7bd148; opacity:.06" d="M9.96 0c2.54 0 5.09.97 7.03 2.91 3.88 3.88 3.88 10.18 0 14.06L9.96 24l-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06A9.92 9.92 0 0 1 9.96 0m0-2C6.77-2 3.77-.76 1.52 1.5c-4.66 4.66-4.66 12.23 0 16.89l7.03 7.03 1.41 1.41 1.41-1.41 7.03-7.03c4.66-4.66 4.66-12.23 0-16.89C16.15-.76 13.15-2 9.96-2z"></path>
            
                    <image x="50%" y="50%" width="16" height="16" transform="translate(-8, -10)" style="border-radius: 50%" href="https://d1ubp9hvv96vde.cloudfront.net/mason/fc9905ebfd40b2dd0581470bcb2aceb4f2158fa9.png?1677252308" clip-path="inset(0% round 15px)"></image>
            
                </svg>
                `
            }
            else{
                pinSVGString =
                `<svg class="qmb-avatar" version="1.1" height="24" width="24" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 19.93 24" style="enable-background:new 0 0 19.93 24" xml:space="preserve">
                    <path style="fill: ${visit.technician.color};" class="st0" d="m9.96 24-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06 3.88-3.88 10.18-3.88 14.06 0 3.88 3.88 3.88 10.18 0 14.06L9.96 24z"/>
                    <path style="fill: ${visit.technician.color}; opacity:.06" class="st0" d="M9.96 0c2.54 0 5.09.97 7.03 2.91 3.88 3.88 3.88 10.18 0 14.06L9.96 24l-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06A9.92 9.92 0 0 1 9.96 0m0-2C6.77-2 3.77-.76 1.52 1.5c-4.66 4.66-4.66 12.23 0 16.89l7.03 7.03 1.41 1.41 1.41-1.41 7.03-7.03c4.66-4.66 4.66-12.23 0-16.89C16.15-.76 13.15-2 9.96-2z" />
            
                    <text x="50%" y="50%"  dominant-baseline="middle" text-anchor="middle" style="color: #FFF">
                        ${visit.technician.name[0]}
                    </text>
                </svg>`
            }




        }
        else {
            pinSVGString =
                `<svg version="1.1" height="24" width="24" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 19.93 24" style="enable-background:new 0 0 19.93 24" xml:space="preserve">
                <path style="fill:#111111;" class="st0" d="m9.96 24-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06 3.88-3.88 10.18-3.88 14.06 0 3.88 3.88 3.88 10.18 0 14.06L9.96 24z"/>
                <path style="fill:#111111; opacity:.06" class="st0" d="M9.96 0c2.54 0 5.09.97 7.03 2.91 3.88 3.88 3.88 10.18 0 14.06L9.96 24l-7.03-7.03c-3.88-3.88-3.88-10.18 0-14.06A9.92 9.92 0 0 1 9.96 0m0-2C6.77-2 3.77-.76 1.52 1.5c-4.66 4.66-4.66 12.23 0 16.89l7.03 7.03 1.41 1.41 1.41-1.41 7.03-7.03c4.66-4.66 4.66-12.23 0-16.89C16.15-.76 13.15-2 9.96-2z" />
                <path style="fill:#aaaaaa" d="M14.32 14.1a2.27 2.27 0 0 0-2.21-1.72H7.8c-1.04 0-1.95.71-2.21 1.72-.08.39.22.78.62.78h7.48c.43-.01.73-.39.63-.78zm-8.1.13C6.4 13.51 7.06 13 7.8 13h4.3c.76 0 1.42.51 1.6 1.25l-7.48-.02zm5.67-4.98c0 1.03-.84 1.88-1.88 1.88s-1.87-.84-1.87-1.88c0-.17-.14-.31-.31-.31-.17 0-.31.14-.31.31a2.5 2.5 0 0 0 5 0c0-.17-.14-.31-.31-.31-.18 0-.32.14-.32.31zm-4.73-.94h5.66c.17 0 .31-.14.31-.31 0-.17-.14-.31-.31-.31h-.31c0-1.27-.96-2.31-2.2-2.47v-.03c0-.17-.14-.31-.31-.31-.18 0-.32.14-.32.31v.03c-1.24.16-2.2 1.2-2.2 2.47h-.31c-.18 0-.32.14-.32.31 0 .17.14.31.31.31zm3.15-2.47c.89.15 1.57.92 1.57 1.84h-1.57V5.84zm-2.2 1.83c0-.93.68-1.69 1.57-1.84v1.86l-1.57-.02z" />
            </svg>`
        }


        return parser.parseFromString(
            pinSVGString,
            "image/svg+xml",
        ).documentElement;
    }

    const pinContent = (visit) => {

        if (visit.technicianId){
            let content = {
                background: visit.technician.color,
                borderColor: `hsl(${hexToHue(visit.technician.color)} 70% 70%)`,
            }


            if (visit.technician.photoUrl){
                const glyphImg = document.createElement("img");
                glyphImg.style.width = "140%";
                glyphImg.style.borderRadius = "50%"
                glyphImg.src = visit.technician.photoUrl
                content.glyph = glyphImg
            }
            else{

                const glyphSvg = document.createElement("svg");
                const glyphSvgText = document.createElement("text");
                const glyphSvgShape = document.createElement("path")
                glyphSvgText.setAttribute("x", "50%");
                glyphSvgText.setAttribute("y", "55%");
                glyphSvgText.setAttribute("dominant-baseline", "middle");
                glyphSvgText.setAttribute("text-anchor", "middle");
                glyphSvgText.innerHTML = visit.technician.name[0];
                glyphSvgText.style.color = "#FFF"
                glyphSvgShape.setAttribute("d", "M256,0C114.62,0,0,114.62,0,256c0,256.15,256,384,256,384s256-127.85,256-384C512,114.62,397.38,0,256,0z");
                glyphSvgShape.setAttribute("fill", content.background);
                glyphSvg.setAttribute("viewBox", "0 0 16 16");
                glyphSvg.appendChild(glyphSvgText);

                const glyphContent = document.createElement("span");
                glyphContent.className = "qmb-avatar"
                glyphContent.appendChild(glyphSvgText);
                content.glyph = glyphContent
            }
            return content
        }
        else {

            const icon = document.createElement("div");
            icon.innerHTML = '<i class="fa-light fa-user-helmet-safety"></i>';
            return {
                glyph: icon,
                background: "#262626",
                borderColor: "#919191",
                glyphColor: "#FFF",
            };
        }

        return {
            background: visit.technician.color,
            borderColor: "rgba(0,0,0,.2)",
        }
    }



    return (
        <div className={"map__figure"}>
            <div ref={mapRef} style={{ width: "100%", height: "100%" }}></div>
        </div>
    );
}

const pinContentTech = (tech) => {
    const parser = new DOMParser();

    const pinSVGString = `
      <svg style="enable-background:new 0 0 19.93 24" xmlns="http://www.w3.org/2000/svg" version="1.1" height="24" width="24" x="0" y="0" viewBox="0 0 24 24" xml:space="preserve">
        <path style="fill: ${tech.color}; stroke-width: 0px" d="M17.19,20.69c-.83,0-1.5-.67-1.5-1.5v-.5h-7v.5c0,.83-.67,1.5-1.5,1.5h-1c-.83,0-1.5-.67-1.5-1.5v-1.26c-.64-.57-1-1.37-1-2.24V6.69c0-1.65,1.35-3,3-3h11c1.65,0,3,1.35,3,3v9c0,.87-.36,1.67-1,2.24v1.26c0,.83-.67,1.5-1.5,1.5h-1ZM17.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM7.19,13.69c-.28,0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5ZM16.05,10.69l-.41-1.62c-.07-.28-.29-.38-.49-.38h-5.97c-.17,0-.39.1-.46.37l-.41,1.63h7.72Z"/>
        <path style="fill: #fff; stroke-width: 0px" d="M17.69,4.19c1.38,0,2.5,1.12,2.5,2.5v9c0,.82-.39,1.54-1,2v1.5c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1h-8v1c0,.55-.45,1-1,1h-1c-.55,0-1-.45-1-1v-1.5c-.61-.46-1-1.18-1-2V6.69c0-1.38,1.12-2.5,2.5-2.5h11M7.69,11.19h9l-.56-2.24c-.11-.47-.51-.76-.97-.76h-5.97c-.43,0-.83.28-.94.76l-.56,2.24M17.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M7.19,15.19c.55,0,1-.45,1-1s-.45-1-1-1-1,.45-1,1,.45,1,1,1M17.69,3.19H6.69c-1.93,0-3.5,1.57-3.5,3.5v9c0,.93.36,1.8,1,2.45v1.05c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2h6c0,1.1.9,2,2,2h1c1.1,0,2-.9,2-2v-1.05c.64-.65,1-1.52,1-2.45V6.69c0-1.93-1.57-3.5-3.5-3.5h0ZM8.97,10.19l.25-1h5.94s.25,1,.25,1h-6.44Z"/>
      </svg>`;

    return parser.parseFromString(
      pinSVGString,
      "image/svg+xml",
    ).documentElement;
}

function CustomPin(visit) {

    const divElement = document.createElement("div");
    divElement.style.width = "20px";
    divElement.style.height = "20px";
    divElement.style.background = visit.technician.color;


    return divElement;


}




function hexToHue(hexColor) {
    // Remove the '#' if it exists at the beginning of the hex color code
    hexColor = hexColor.replace(/^#/, '');

    // Parse the hex values for red, green, and blue
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);

    // Convert the RGB values to the HSL color model
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let hue;

    if (max === min) {
        hue = 0; // Achromatic (gray)
    } else {
        const delta = max - min;
        if (max === r) {
            hue = ((g - b) / delta) % 6;
        } else if (max === g) {
            hue = (b - r) / delta + 2;
        } else {
            hue = (r - g) / delta + 4;
        }
        hue *= 60; // Convert to degrees
    }

    if (hue < 0) {
        hue += 360; // Make sure hue is non-negative
    }

    return Math.round(hue); // Round to the nearest whole number
}

export default GoogleMap
