import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import I18n, { translate } from '@utils/i18n';
import { startDragging, status as draggingStatuses, types } from '../Redux/draggingSlice';
import { assignVisitToTechnician } from '../Redux/visitsSlice';
import { showEditModal } from '../Redux/editModalSlice';
import { DateTime } from 'luxon';

const namespace = 'features.map_scheduler';

function VisitItem({ visit }) {
  const timeZone = useSelector((state) => state.settings.timeZone);
  const dispatch = useDispatch();
  const draggingType = useSelector((state) => state.dragging.type);
  const draggingData = useSelector((state) => state.dragging.data);
  const draggingStatus = useSelector((state) => state.dragging.status);
  const mapActive = useSelector((state) => state.map.mapActive);
  const tooltipRef = useRef();

  useEffect(() => {
    $(tooltipRef.current).kendoTooltip({
      position: 'right'
    });
  }, []);

  const HandleMouseDown = (e) => {
    e.stopPropagation();
    dispatch(startDragging({ x: e.clientX, y: e.clientY, data: visit, type: types.VISIT }));
  };

  const HandleMouseUp = (e) => {
    if (draggingStatus === draggingStatuses.DRAGGING && draggingType === types.TECHNICIAN) {
      dispatch(assignVisitToTechnician({ visit, technician: draggingData }));
    }
  };

  const HandleClick = (e) => {
    dispatch(showEditModal(visit));
  };

  const typeTitle = (type) => {
    return translate(`visits.types.${type}`, { namespace }) || translate(`visits.types.default`, { namespace });
  };

  if (mapActive) {
    return (
      <li
        className="list__option--visit"
        onMouseDown={HandleMouseDown}
        onMouseUp={HandleMouseUp}
        onClick={HandleClick}
        style={{ userSelect: 'none' }}>
        <button type="button">
          <span className="option__header">
            <strong className="option__title">{visit.building}</strong>
            <span className="option__type" ref={tooltipRef} title={typeTitle(visit.type)}>
              <TypeIcon type={visit.type} frequency={visit.details.frequency} />
            </span>
          </span>
          <span className="option__address">{visit.address}</span>
          <span className="option__footer">
            <span className="option__date">
              <i className={visit.statusIcon} style={{ color: visit.statusColor }} />
              &nbsp;
              {DateTime.fromISO(visit.startTime, { zone: timeZone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
            </span>
            <TechnicianInfo technician={visit.technician} technicianId={visit.technicianId} fixedWidth={false} />
          </span>
        </button>
      </li>
    );
  }

  return (
    <li
      className="list__option--visit"
      onMouseDown={HandleMouseDown}
      onMouseUp={HandleMouseUp}
      onClick={HandleClick}
      style={{ userSelect: 'none' }}>
      <button type="button" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="option__type--fixed-width" title={typeTitle(visit.type)}>
          <TypeIcon type={visit.type} />
          {visit.type === 'Visit' ? 'Visit' : 'Inspection'}
        </span>
        <span className="option__title--fixed-width">{visit.building}</span>
        <span className="option__address--fixed-width">{visit.address}</span>
        <span className="option__date--fixed-width">
          {DateTime.fromISO(visit.startTime, { zone: timeZone }).toFormat("M/d/yyyy' @ 'hh:mm a")}
        </span>
        <span className="option__status--fixed-width">
          <i className={visit.statusIcon} style={{ color: visit.statusColor }} />
          &nbsp;{visit.humanizedStatus}
        </span>
        <span className="option__freq--fixed-width">{visit.details.frequency ?? ''}</span>
        <span className="option__desc--fixed-width">{visit.description}</span>
        <TechnicianInfo technician={visit.technician} technicianId={visit.technicianId} fixedWidth />
        <span className="option__duration--fixed-width">{visit.humanized_event_duration}</span>
      </button>
    </li>
  );
}

function TypeIcon({ type, frequency }) {
  switch (type) {
    case 'Visit':
      return <i className="fa-light fa-screwdriver-wrench" />;
    case 'Inspection':
    case 'InspectionVisit':
      return (
        <>
          {frequency && <small>{frequency}</small>}
          <i className="fa-light fa-clipboard-check" />
        </>
      );
    default:
      return <i className="fa-light fa-square-question" />;
  }
}

function TechnicianInfo({ technician, technicianId, fixedWidth }) {
  if (!technicianId) {
    return (
      <span className={`option__tech--unassigned${fixedWidth ? '--fixed-width' : ''}`}>
        <i className="fa-light fa-user-helmet-safety" /> {I18n.t('generic.unassigned')}
      </span>
    );
  }

  if (!technician.photoUrl) {
    return (
      <span className={`option__tech${fixedWidth ? '--fixed-width' : ''}`}>
        <span className="qmb-avatar--16">
          <svg viewBox="0 0 16 16">
            <circle cx="8" cy="8" r="8" style={{ fill: technician.color }} />
            <text x="50%" y="55%" dominantBaseline="middle" textAnchor="middle">
              {technician.name[0]}
            </text>
          </svg>
        </span>
        {technician.name}
      </span>
    );
  }

  return (
    <span className={`option__tech${fixedWidth ? '--fixed-width' : ''}`}>
      <span className="qmb-avatar--16">
        <img
          style={{ borderRadius: '50%', border: `1px solid ${technician.color}`, boxSizing: 'border-box' }}
          src={technician.photoUrl}
        />
      </span>
      {technician.name}
    </span>
  );
}

export default VisitItem;
