export const THIS_WEEK = 'this_week';
export const LAST_WEEK = 'last_week';
export const NEXT_WEEK = 'next_week';
export const THIS_MONTH = 'this_month';
export const LAST_MONTH = 'last_month';
export const NEXT_MONTH = 'next_month';
export const THIS_QUARTER = 'this_quarter';
export const LAST_QUARTER = 'last_quarter';
export const NEXT_QUARTER = 'next_quarter';
export const THIS_YEAR = 'this_year';
export const LAST_YEAR = 'last_year';
export const CUSTOM_RANGE = 'custom_range';

export const DATE_FORMAT = 'M/D/YYYY';

export const RANGE_OPTIONS = [
  THIS_WEEK,
  NEXT_WEEK,
  LAST_WEEK,
  THIS_MONTH,
  NEXT_MONTH,
  LAST_MONTH,
  THIS_QUARTER,
  NEXT_QUARTER,
  LAST_QUARTER,
  THIS_YEAR,
  LAST_YEAR,
  CUSTOM_RANGE
];
export default RANGE_OPTIONS;
