// I18n comes from Sprockets now
const { I18n } = window;

export const translate = (key, params) => {
  const { namespace } = params;
  delete params.namespace;

  const prefix = namespace ? `${namespace}.` : undefined;

  return I18n.t(`${prefix}${key}`, params);
};

export default I18n;
