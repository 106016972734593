import { actionTypes } from '../reducer';

export default ({
  filters,
  dispatch,
  territoriesForSelect,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  salesPeopleForSelect,
  assetsForSelect,
  screen
}) => {
  if (screen === 'inspection' || screen === 'building') {
    return inspectionFilterList({
      filters,
      dispatch,
      resolutionStatusesForSelect,
      deficiencyStatusesForSelect,
      assetsForSelect,
      screen
    });
  }

  if (screen === 'account') {
    return accountFilterList({
      filters,
      dispatch,
      territoriesForSelect,
      resolutionStatusesForSelect,
      deficiencyStatusesForSelect,
      salesPeopleForSelect,
      assetsForSelect,
      screen
    });
  }

  return indexFilterList({
    filters,
    dispatch,
    territoriesForSelect,
    resolutionStatusesForSelect,
    deficiencyStatusesForSelect,
    salesPeopleForSelect,
    assetsForSelect
  });
};

const indexFilterList = ({
  filters,
  dispatch,
  territoriesForSelect,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  salesPeopleForSelect,
  assetsForSelect
}) => [
  {
    field: 'isResolved',
    locale: 'include_resolved_deficiencies',
    type: 'checkbox',
    active: filters.active.isResolved,
    value: filters.values.isResolved,
    onChange: onChangeReactFilter('isResolved', dispatch),
    onToggle: onToggleFilter('isResolved', dispatch)
  },
  {
    field: 'dateOpened',
    locale: 'date_opened',
    type: 'dateRangePicker',
    active: filters.active.dateOpened,
    values: filters.values.dateOpened,
    onChange: onChangeDateFilter('dateOpened', dispatch),
    onToggle: onToggleFilter('dateOpened', dispatch)
  },
  {
    field: 'building',
    locale: 'building',
    type: 'searchSelect',
    active: filters.active.building,
    value: filters.values.building,
    onChange: onChangeSearchFilter('building', dispatch),
    onToggle: onToggleFilter('building', dispatch)
  },
  {
    field: 'account',
    locale: 'account',
    type: 'searchSelect',
    active: filters.active.account,
    value: filters.values.account,
    onChange: onChangeSearchFilter('account', dispatch),
    onToggle: onToggleFilter('account', dispatch)
  },
  {
    field: 'territory',
    locale: 'territory',
    type: 'commonSelect',
    active: filters.active.territory,
    optionsForSelect: territoriesForSelect,
    value: filters.values.territory,
    onChange: onChangeReactFilter('territory', dispatch),
    onToggle: onToggleFilter('territory', dispatch)
  },
  {
    field: 'assets',
    locale: 'asset',
    type: 'treeSelect',
    active: filters.active.assets,
    value: filters.values.assets,
    optionsForSelect: assetsForSelect,
    onChange: onChangeReactFilter('assets', dispatch),
    onToggle: onToggleFilter('assets', dispatch)
  },
  {
    field: 'resolutionStatus',
    locale: 'resolution_status',
    type: 'commonSelect',
    active: filters.active.resolutionStatus,
    optionsForSelect: resolutionStatusesForSelect,
    value: filters.values.resolutionStatus,
    onChange: onChangeReactFilter('resolutionStatus', dispatch),
    onToggle: onToggleFilter('resolutionStatus', dispatch)
  },
  {
    field: 'deficiencyStatus',
    locale: 'deficiency_status',
    type: 'commonSelect',
    active: filters.active.deficiencyStatus,
    optionsForSelect: deficiencyStatusesForSelect,
    value: filters.values.deficiencyStatus,
    onChange: onChangeReactFilter('deficiencyStatus', dispatch),
    onToggle: onToggleFilter('deficiencyStatus', dispatch)
  },
  {
    field: 'technician',
    backendField: 'technician',
    locale: 'reported_by',
    type: 'multiSelectWithAvatar',
    active: filters.active.technician,
    value: filters.values.technician,
    withUnassigned: true,
    onlyFullTech: true,
    onChange: onChangeSearchFilter('technician', dispatch),
    onToggle: onToggleFilter('technician', dispatch)
  },
  {
    field: 'salesPerson',
    locale: 'sales_person',
    type: 'commonSelect',
    active: filters.active.salesPerson,
    optionsForSelect: salesPeopleForSelect,
    value: filters.values.salesPerson,
    onChange: onChangeReactFilter('salesPerson', dispatch),
    onToggle: onToggleFilter('salesPerson', dispatch)
  }
];

const inspectionFilterList = ({
  filters,
  dispatch,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  assetsForSelect,
  screen
}) => [
  {
    field: 'isResolved',
    locale: 'include_resolved_deficiencies',
    type: 'checkbox',
    active: filters.active.isResolved,
    value: filters.values.isResolved,
    onChange: onChangeReactFilter('isResolved', dispatch),
    onToggle: onToggleFilter('isResolved', dispatch)
  },
  {
    field: 'dateOpened',
    locale: 'date_opened',
    type: 'dateRangePicker',
    active: filters.active.dateOpened,
    values: filters.values.dateOpened,
    onChange: onChangeDateFilter('dateOpened', dispatch),
    onToggle: onToggleFilter('dateOpened', dispatch)
  },
  {
    field: 'assets',
    locale: 'asset',
    type: 'treeSelect',
    active: filters.active.assets,
    value: filters.values.assets,
    optionsForSelect: assetsForSelect,
    onChange: onChangeReactFilter('assets', dispatch),
    onToggle: onToggleFilter('assets', dispatch)
  },
  {
    field: 'resolutionStatus',
    locale: 'resolution_status',
    type: 'commonSelect',
    active: filters.active.resolutionStatus,
    optionsForSelect: resolutionStatusesForSelect,
    value: filters.values.resolutionStatus,
    onChange: onChangeReactFilter('resolutionStatus', dispatch),
    onToggle: onToggleFilter('resolutionStatus', dispatch)
  },
  {
    field: 'deficiencyStatus',
    locale: 'deficiency_status',
    type: 'commonSelect',
    active: filters.active.deficiencyStatus,
    optionsForSelect: deficiencyStatusesForSelect,
    value: filters.values.deficiencyStatus,
    onChange: onChangeReactFilter('deficiencyStatus', dispatch),
    onToggle: onToggleFilter('deficiencyStatus', dispatch)
  },
  {
    field: 'technician',
    backendField: 'technician',
    locale: 'reported_by',
    type: 'multiSelectWithAvatar',
    active: filters.active.technician,
    value: filters.values.technician,
    withUnassigned: true,
    onlyFullTech: true,
    onChange: onChangeSearchFilter('technician', dispatch),
    onToggle: onToggleFilter('technician', dispatch)
  },
  {
    field: screen,
    type: 'hidden',
    active: true,
    value: filters.values[screen]
  }
];

const accountFilterList = ({
  filters,
  dispatch,
  territoriesForSelect,
  resolutionStatusesForSelect,
  deficiencyStatusesForSelect,
  salesPeopleForSelect,
  assetsForSelect,
  screen
}) => [
  {
    field: 'isResolved',
    locale: 'include_resolved_deficiencies',
    type: 'checkbox',
    active: filters.active.isResolved,
    value: filters.values.isResolved,
    onChange: onChangeReactFilter('isResolved', dispatch),
    onToggle: onToggleFilter('isResolved', dispatch)
  },
  {
    field: 'dateOpened',
    locale: 'date_opened',
    type: 'dateRangePicker',
    active: filters.active.dateOpened,
    values: filters.values.dateOpened,
    onChange: onChangeDateFilter('dateOpened', dispatch),
    onToggle: onToggleFilter('dateOpened', dispatch)
  },
  {
    field: 'building',
    locale: 'building',
    type: 'searchSelect',
    active: filters.active.building,
    value: filters.values.building,
    onChange: onChangeSearchFilter('building', dispatch),
    onToggle: onToggleFilter('building', dispatch)
  },
  {
    field: screen,
    type: 'hidden',
    active: true,
    value: filters.values[screen]
  },
  {
    field: 'territory',
    locale: 'territory',
    type: 'commonSelect',
    active: filters.active.territory,
    optionsForSelect: territoriesForSelect,
    value: filters.values.territory,
    onChange: onChangeReactFilter('territory', dispatch),
    onToggle: onToggleFilter('territory', dispatch)
  },
  {
    field: 'assets',
    locale: 'asset',
    type: 'treeSelect',
    active: filters.active.assets,
    value: filters.values.assets,
    optionsForSelect: assetsForSelect,
    onChange: onChangeReactFilter('assets', dispatch),
    onToggle: onToggleFilter('assets', dispatch)
  },
  {
    field: 'resolutionStatus',
    locale: 'resolution_status',
    type: 'commonSelect',
    active: filters.active.resolutionStatus,
    optionsForSelect: resolutionStatusesForSelect,
    value: filters.values.resolutionStatus,
    onChange: onChangeReactFilter('resolutionStatus', dispatch),
    onToggle: onToggleFilter('resolutionStatus', dispatch)
  },
  {
    field: 'deficiencyStatus',
    locale: 'deficiency_status',
    type: 'commonSelect',
    active: filters.active.deficiencyStatus,
    optionsForSelect: deficiencyStatusesForSelect,
    value: filters.values.deficiencyStatus,
    onChange: onChangeReactFilter('deficiencyStatus', dispatch),
    onToggle: onToggleFilter('deficiencyStatus', dispatch)
  },
  {
    field: 'technician',
    backendField: 'technician',
    locale: 'reported_by',
    type: 'multiSelectWithAvatar',
    active: filters.active.technician,
    value: filters.values.technician,
    withUnassigned: true,
    onlyFullTech: true,
    onChange: onChangeSearchFilter('technician', dispatch),
    onToggle: onToggleFilter('technician', dispatch)
  },
  {
    field: 'salesPerson',
    locale: 'sales_person',
    type: 'commonSelect',
    active: filters.active.salesPerson,
    optionsForSelect: salesPeopleForSelect,
    value: filters.values.salesPerson,
    onChange: onChangeReactFilter('salesPerson', dispatch),
    onToggle: onToggleFilter('salesPerson', dispatch)
  }
];

const onToggleFilter = (fieldName, dispatch) => {
  return () => {
    dispatch({ type: actionTypes.FILTER_TOGGLED, field: fieldName });
  };
};

const onChangeDateFilter = (fieldName, dispatch) => {
  return (dateFieldName, value) => {
    dispatch({
      type: actionTypes.DATE_RANGE_FILTER_CHANGED,
      field: fieldName,
      dateField: dateFieldName,
      value
    });
  };
};

const onChangeSearchFilter = (fieldName, dispatch) => {
  return (e) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value: e.sender.value(), field: fieldName });
  };
};

const onChangeReactFilter = (fieldName, dispatch) => {
  return (value) => {
    dispatch({ type: actionTypes.FILTER_CHANGED, value, field: fieldName });
  };
};
