import api from '@utils/axios';
import { calendarPath, eventsCalendarPath } from 'routes';

export const updateEvent = (event) => {
  return api.put(calendarPath({ id: event.objectId, type: event.type, _options: true }), data(event));
};

const data = (event) => {
  if (event.type === 'Inspection')
    return {
      inspection: {
        status_code: event.status,
        technician_id: event.technicianId,
        scheduled_date: event.start,
        projected_duration_mins: event.durationMins
      }
    };

  if (event.type === 'InspectionVisit')
    return {
      inspection_visit: {
        technician_id: event.technicianId,
        scheduled_date: event.start,
        duration_mins: event.durationMins,
        inspection_attributes: { status_code: event.status, id: event.inspectionId }
      }
    };

  if (event.type === 'Visit')
    return {
      work_order_visit: {
        visit_status: event.status,
        technician_id: event.technicianId,
        scheduled_date: event.start,
        duration: event.durationMins
      }
    };
};

export const fetchEvents = ({ range, filters }) => {
  return api.get(eventsCalendarPath({ format: 'json' }), {
    params: { start: range.startStr, end: range.endStr, filters: filters.values }
  });
};
