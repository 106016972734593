/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useReducer, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import I18n, { translate } from '@utils/i18n';

import SearchSelect from '@components/Kendo/SearchSelect';
import BooleanSelect from '@components/Kendo/BooleanSelect';
import DateRangePicker from '@components/Kendo/DateRangePicker';

import { DEFAULT_PAGE_SIZE, pageSizes, buildGridSettings } from '@constants/Kendo/gridSettings';
import { actionTypes, reducer, buildInitialFiltersState, isDateRangeStateField } from './IndexTableReducer';

import restoreGridPage from '../../../helpers/restoreGridPage';
import CustomScrollbar from '../../../components/CustomScrollbar/CustomScrollbar';

// Add JQuery
const { kendo } = window;
const { $, confirm, ConfirmationModal } = window;

const DEFAULT_NAMESPACE = 'inspection_series.datatable';
const DEFAULT_CACHE_KEY = 'inspection_series/index';
const DEFAULT_NON_HIDABLE_COLUMNS = ['selectInspectionSeries', 'actions'];

// TODO: re-visit and see how we could minify the relationship between sprockets and webpacker part.
// Ideally, we should not mix them. But to be eliminate the waiting time for the duration
// of whole project being re-written and to be able to use React now - it's worth it.
function IndexTable(props) {
  const {
    namespace,
    inspectionSeriesUrl,
    buildingsForSelect,
    accountsForSelect,
    cacheKey,
    defaultHiddenColumns,
    nonHidableColumns,
    hasShowExpiredSeriesFilter,
    hasShowExpirationDateFilter,
    hasShowBuildingFilter,
    hasShowAccountFilter,
    hasShowStatusFilter,
    hasShowAutoExtendFilter,
    hasDisableAllFilters,
    hasDisableAllBulkActions,
    pageSize
  } = props;

  const kendoCacheKey = `kendo/${cacheKey}`;
  const containerRef = useRef();
  const tableRef = useRef();
  const [state, dispatch] = useReducer(reducer, {
    tableFingerprint: undefined,
    inspection_series: [],
    filters: buildInitialFiltersState()
  });

  // Mechanism to make state being up-to-date in Kendo grid callbacks.
  // Src: https://stackoverflow.com/a/60643670/20974169
  const filtersRef = useRef();
  filtersRef.current = state.filters;

  const tableFingerprintRef = useRef();
  tableFingerprintRef.current = state.tableFingerprint;

  const isMounted = !!state.tableFingerprint;
  const isMountedForCallbacks = () => !!tableFingerprintRef.current;
  let cachedPage = 1;

  useEffect(() => {
    setupTable();
    setupTableControls();
    loadCacheFromLocalStorage();

    dispatch({ type: actionTypes.TABLE_RELOADED });
  }, []);

  useEffect(() => {
    if (!isMounted) return;

    reloadTable();
    reloadTableControls();
  }, [state.tableFingerprint]);

  // ===================== JS Part ================================

  const setupTable = () => {
    const gridSettings = buildGridSettings(tableRef.current, {
      columns: [
        {
          field: 'selectInspectionSeries',
          title: translate('table.headers.select_inspection_series', { namespace }),
          sortable: false,
          columnMenu: false,
          headerTemplate: checkBoxHeaderTemplate,
          template: checkBoxTemplate,
          hidden: defaultHiddenColumns.includes('selectInspectionSeries'), // to hide column by default
          menu: !nonHidableColumns.includes('selectInspectionSeries'), // to show column in menu
          attributes: {
            class: 'table__cell--selectable'
          }
        },
        {
          field: 'id',
          title: translate('table.headers.id', { namespace }),
          template: idTemplate,
          hidden: defaultHiddenColumns.includes('id'),
          menu: !nonHidableColumns.includes('id')
        },
        {
          field: 'referenceNumber',
          title: translate('table.headers.reference_number', { namespace }),
          template: referenceNumberTemplate,
          hidden: defaultHiddenColumns.includes('referenceNumber'),
          menu: !nonHidableColumns.includes('referenceNumber')
        },
        {
          field: 'contractNumber',
          title: translate('table.headers.contract_number', { namespace }),
          template: contractNumberTemplate,
          hidden: defaultHiddenColumns.includes('contractNumber'),
          menu: !nonHidableColumns.includes('contractNumber')
        },
        {
          field: 'building',
          title: I18n.t('generic.building'),
          template: buildingTemplate,
          hidden: defaultHiddenColumns.includes('building'),
          menu: !nonHidableColumns.includes('building')
        },
        {
          field: 'account',
          title: I18n.t('generic.account'),
          template: accountTemplate,
          hidden: defaultHiddenColumns.includes('account'),
          menu: !nonHidableColumns.includes('account')
        },
        {
          field: 'frequencies',
          title: translate('table.headers.frequencies', { namespace }),
          sortable: false,
          template: frequenciesTemplate,
          hidden: defaultHiddenColumns.includes('frequencies'),
          menu: !nonHidableColumns.includes('frequencies')
        },
        {
          field: 'expirationDate',
          title: translate('table.headers.expiration_date', { namespace }),
          template: expirationDateTemplate,
          hidden: defaultHiddenColumns.includes('expirationDate'),
          menu: !nonHidableColumns.includes('expirationDate')
        },
        {
          field: 'systemAsset',
          title: translate('table.headers.system_asset', { namespace }),
          sortable: false,
          template: systemAssetTemplate,
          hidden: defaultHiddenColumns.includes('systemAsset'),
          menu: !nonHidableColumns.includes('systemAsset')
        },
        {
          field: 'inspectionsCount',
          title: translate('table.headers.inspections_count', { namespace }),
          sortable: false,
          template: inspectionsCountTemplate,
          hidden: defaultHiddenColumns.includes('inspectionsCount'),
          menu: !nonHidableColumns.includes('inspectionsCount')
        },
        {
          field: 'status',
          title: translate('table.headers.status', { namespace }),
          template: statusTemplate,
          hidden: defaultHiddenColumns.includes('status'),
          menu: !nonHidableColumns.includes('status')
        },
        {
          field: 'autoExtend',
          title: translate('table.headers.auto_extend', { namespace }),
          template: autoExtendTemplate,
          hidden: defaultHiddenColumns.includes('autoExtend'),
          menu: !nonHidableColumns.includes('autoExtend')
        },
        {
          field: 'actions',
          title: ' ',
          sortable: false,
          columnMenu: false,
          template: actionsTemplate,
          hidden: defaultHiddenColumns.includes('actions'),
          menu: !nonHidableColumns.includes('actions'),
          attributes: {
            class: 'table__cell--options--col-actions'
          }
        }
      ],
      pageable: {
        pageSize,
        pageSizes: pageSizes(pageSize)
      },
      dataSource: {
        schema: {
          model: {
            fields: {
              id: { type: 'number' },
              referenceNumber: { type: 'string' },
              contractNumber: { type: 'string' },
              building: { type: 'string' },
              account: { type: 'string' },
              frequencies: { type: 'string' },
              expirationDate: { type: 'string' },
              systemAsset: { type: 'string' },
              inspectionsCount: { type: 'number' },
              status: { type: 'string' },
              autoExtend: { type: 'string' }
            }
          }
        },
        sort: { field: 'id', dir: 'desc' }
      },
      culture: $('body').attr('data-locale'),
      scrollable: false,
      columnShow: saveCacheToLocalStorage,
      columnHide: saveCacheToLocalStorage,
      dataBinding: () => {
        $('.rerender-inspection-series-popup').remove();
      },
      dataBound: () => {
        new ConfirmationModal().init();
        saveCacheToLocalStorage();
        // eslint-disable-next-line no-undef
        loadDropdowns({ gridSelector: '#inspection-series-grid' });
        restoreGridPage(cachedPage, $(tableRef.current).data('kendoGrid').dataSource);
      }
    });
    $(tableRef.current).kendoGrid(gridSettings);
  };

  const checkBoxTemplate = ReactDOMServer.renderToString(
    <div className="--checkbox">
      <input type="checkbox" data-inspection-series-id="#= id #" data-js-bulk-single-checkbox />
    </div>
  );

  const checkBoxHeaderTemplate = ReactDOMServer.renderToString(
    <input type="checkbox" className="--checkbox" data-js-bulk-all-checkbox />
  );

  const expirationDateTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (expirationDate) { #'}
      <span>#= expirationDate #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const systemAssetTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (systemAsset) { #'}
      <span>#= systemAsset #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const inspectionsCountTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (inspectionsCount) { #'}
      <span>#= inspectionsCount #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const statusTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (status) { #'}
      <span>#= status #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const autoExtendTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (autoExtend) { #'}
      <span>#= autoExtend #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const idTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (id) { #'}
      {'# if (showLink) { #'}
      <u>
        <a href="#= showLink #">#= id #</a>
      </u>
      {'# } else { #'}
      <span>#= id #</span>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const referenceNumberTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (referenceNumber) { #'}
      <span>#= referenceNumber #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const contractNumberTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (contractNumber) { #'}
      <span>#= contractNumber #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const accountTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (account) { #'}
      {'# if (account == window.location.pathname) { #'}
      <span>#= accountName #</span>
      {'# } else { #'}
      <u>
        <a href="#= account #">#= accountName #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const frequenciesTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (frequencies) { #'}
      <span>#= frequencies #</span>
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const buildingTemplate = ReactDOMServer.renderToString(
    <>
      {'# if (building) { #'}
      {'# if (building == window.location.pathname) { #'}
      <span>#= buildingName #</span>
      {'# } else { #'}
      <u>
        <a href="#= building #">#= buildingName #</a>
      </u>
      {'# } #'}
      {'# } else { #'}
      <span>-</span>
      {'# } #'}
    </>
  );

  const actionsTemplate = ReactDOMServer.renderToString(
    <div>
      <button type="button" className="qmb-control--icon--row-actions --react">
        <i className="fa-regular fa-ellipsis" />
      </button>
      <div id="inspection_series_popup_#= id #" className="qmb-popup--action-list rerender-inspection-series-popup">
        <ul role="presentation">
          {'# if (showLink) { #'}
          <li>
            <a
              href="#= showLink #"
              title={translate('table.buttons.view_detail', { namespace })}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer">
              <i className="fa-light fa-eye" /> {translate('table.buttons.view_detail', { namespace })}
            </a>
          </li>
          {'# } #'}

          {'# if (!isCancelled) { #'}
          <li>
            <a
              href="#= editStatusLink #"
              title={translate('table.buttons.cancel', { namespace })}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer"
              data-remote="true">
              <i className="fa-light fa-ban" /> {translate('table.buttons.cancel', { namespace })}
            </a>
          </li>

          <li>
            <a
              href="#= extendSeriesLink #"
              title={translate('table.buttons.extend', { namespace })}
              className="qmb-control"
              target="blank"
              rel="noopener noreferrer"
              data-remote="true">
              <i className="fa-light fa-rectangle-history-circle-plus" />
              {translate('table.buttons.extend', { namespace })}
            </a>
          </li>
          {'# } #'}
        </ul>
      </div>
    </div>
  );

  const saveCacheToLocalStorage = () => {
    if (!isMountedForCallbacks()) return;

    const grid = $(tableRef.current).data('kendoGrid');
    const cacheData = {};

    cacheData.hiddenColumns = grid.columns.filter((column) => column.hidden).map((column) => column.field);
    cacheData.stateFilters = filtersRef.current;
    // eslint-disable-next-line no-underscore-dangle
    cacheData.kendoSortSettings = grid.dataSource._sort;
    cacheData.page = grid.dataSource.page();

    const cacheItem = {
      data: cacheData
    };

    localStorage.setItem(kendoCacheKey, kendo.stringify(cacheItem));
  };

  const setupTableControls = () => {
    $(tableRef.current).on('change', '[data-js-bulk-all-checkbox]', onSelectAllToggle);
    $(tableRef.current).on('click', '[data-js-bulk-single-checkbox]', onCheckboxToggle);
  };

  const onCheckboxToggle = (e) => {
    const $controls = $('.js-bulk-action', containerRef.current);

    const checkbox = e.currentTarget;
    const inspectionSeriesId = checkbox.getAttribute('data-inspection-series-id');

    const $anyCheckedCheckbox = $('[data-js-bulk-single-checkbox]:checked', tableRef.current);
    $controls.attr('disabled', $anyCheckedCheckbox.length === 0);

    if (checkbox.checked) {
      dispatch({ type: actionTypes.INSPECTION_SERIES_SELECTED, id: inspectionSeriesId });
    } else {
      $('[data-js-bulk-all-checkbox]', tableRef.current).prop('checked', false);
      dispatch({ type: actionTypes.INSPECTION_SERIES_DESELECTED, id: inspectionSeriesId });
    }
  };

  const onSelectAllToggle = (e) => {
    const $checkbox = $(e.currentTarget);

    if (e.currentTarget.checked) {
      $('[data-js-bulk-single-checkbox]', tableRef.current).prop('checked', true);
      dispatch({ type: actionTypes.INSPECTION_SERIESES_DESELECTED });

      $('[data-js-bulk-single-checkbox]', tableRef.current).each((_index, row) => {
        dispatch({ type: actionTypes.INSPECTION_SERIES_SELECTED, id: row.getAttribute('data-inspection-series-id') });
      });
    } else {
      $('[data-js-bulk-single-checkbox]', tableRef.current).prop('checked', false);
      dispatch({ type: actionTypes.INSPECTION_SERIESES_DESELECTED });
    }

    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', !$checkbox.is(':checked'));
  };

  const loadCacheFromLocalStorage = () => {
    if (!localStorage.getItem(kendoCacheKey)) return;

    try {
      const cacheItem = JSON.parse(localStorage.getItem(kendoCacheKey));

      const cacheData = cacheItem.data;
      const grid = $(tableRef.current).data('kendoGrid');

      if (cacheData.hiddenColumns) {
        cacheData.hiddenColumns.forEach((fieldName) => grid.hideColumn(fieldName));

        grid.columns
          .map((column) => column.field)
          .filter((column) => !cacheData.hiddenColumns.includes(column))
          .forEach((fieldName) => grid.showColumn(fieldName));
      } else {
        grid.columns.map((column) => column.field).forEach((fieldName) => grid.showColumn(fieldName));
      }

      // eslint-disable-next-line no-underscore-dangle
      if (cacheData.kendoSortSettings) grid.dataSource._sort = cacheData.kendoSortSettings;
      if (cacheData.stateFilters) {
        dispatch({ type: actionTypes.LOAD_FILTERS_FROM_CACHE, filters: cacheData.stateFilters });
      }
      if (cacheData.page) {
        cachedPage = cacheData.page;
      }
    } catch {
      console.log(`Issue with Kendo grid cache: ${kendoCacheKey}`);
      localStorage.removeItem(kendoCacheKey);
    }
  };

  const reloadTable = () => {
    const filtersData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(state.filters)) {
      if (!value) continue;
      // Date filters require a bit of different checks around them in different places.
      // If that part would be missed, it won't be critical, such as Back-End is prepared for empty values.
      //
      // This part is only about highlighting the grid header with red background
      // when inspectionDate field was used in filters.
      if (isDateRangeStateField(state.filters[key]) && !value.startDate && !value.endDate) continue;

      filtersData.push({ field: key, operator: 'eq', value });
    }
    $(tableRef.current).data('kendoGrid').dataSource.filter(filtersData);
  };

  const reloadTableControls = () => {
    const $controls = $('.js-bulk-action', containerRef.current);
    $controls.attr('disabled', true);

    const $selectAll = $('[data-js-bulk-all-checkbox], [data-js-bulk-single-checkbox]', tableRef.current);
    $selectAll.prop('checked', false);
  };

  const onBulkExtend = (e) => {
    e.preventDefault();
    // eslint-disable-next-line no-alert
    if (!state.inspection_series.length) return alert(translate('table.select_series', { namespace }));

    $('#inspection_series_ids').val(state.inspection_series.join());

    $('#inspection_series_extend_by_years').on('change', (event) => {
      const extendExpirationDateByYears = parseInt(event.target.value, 10) || 0;

      if (extendExpirationDateByYears < 1 || extendExpirationDateByYears > 10) {
        $('#validation-errors').text('Value must be >= 1 and <= 10');
      } else {
        $('#validation-errors').text('');
      }
    });

    $('#bulk-extend-series-submit').on('click', (event) => {
      if ($('#validation-errors').text().length) {
        event.preventDefault();
      }
    });

    $('#bulk-extend-series-modal').modal({ keyboard: false }).show();
  };

  const onChangeDateFilter = (fieldName) => {
    return (dateFieldName, value) => {
      dispatch({
        type: actionTypes.DATE_RANGE_FILTER_CHANGED,
        field: fieldName,
        dateField: dateFieldName,
        value
      });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const onChangeCommonFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, field: fieldName, value: e.target.value });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const onChangeSearchFilter = (fieldName) => {
    return (e) => {
      dispatch({ type: actionTypes.FILTER_CHANGED, field: fieldName, value: e.sender.element[0].value });
      dispatch({ type: actionTypes.TABLE_RELOADED });
    };
  };

  const onResetFilters = () => {
    dispatch({ type: actionTypes.FILTERS_RESET });
    dispatch({ type: actionTypes.TABLE_RELOADED });
  };

  // For more complicated scenarios, when you have issues with cached Kendo Grid,
  // to clear all Kendo Grid caches, use the next snippet:
  //
  // Object
  //   .keys(localStorage)
  //   .filter(key => key.startsWith('kendo/'))
  //   .forEach(key => {
  //     console.log("Removing item: ", key);
  //     localStorage.removeItem(key)
  //   });
  //
  const onResetCache = () => {
    if (!confirm(I18n.t('generic.are_you_sure'))) return;

    localStorage.removeItem(kendoCacheKey);
    window.location.reload();
  };

  // ===================== HTML Part ================================
  return (
    <div ref={containerRef}>
      {!hasDisableAllFilters && (
        <article id="filters" className="qmb-filters">
          <span className="filters__label">{translate('filters.title', { namespace })}</span>

          <fieldset className="filters__group">
            {hasShowAccountFilter && !!accountsForSelect.length && (
              <div className="filters__option --active">
                <SearchSelect
                  name="account"
                  value={state.filters.account}
                  dataUrl="/lookup/accounts"
                  valueMapUrl="/value-map/account"
                  elements={accountsForSelect}
                  label={translate('filters.account', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'account',
                    onChange: onChangeSearchFilter('account')
                  }}
                />
              </div>
            )}

            {hasShowBuildingFilter && !!buildingsForSelect.length && (
              <div className="filters__option --active">
                <SearchSelect
                  name="building"
                  value={state.filters.building}
                  dataUrl="/lookup/buildings"
                  valueMapUrl="/value-map/building"
                  elements={buildingsForSelect}
                  label={translate('filters.building', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'building',
                    onChange: onChangeSearchFilter('building')
                  }}
                  template="
                  <div>#: label #</div>
                  <sub>#: address #</sub>
                  "
                />
              </div>
            )}

            {hasShowExpirationDateFilter && (
              <DateRangePicker
                name="expiration_date"
                onAnyChange={onChangeDateFilter('expirationDate')}
                values={{
                  rangeType: state.filters.expirationDate.rangeType,
                  startDate: state.filters.expirationDate.startDate,
                  endDate: state.filters.expirationDate.endDate
                }}
                label={translate('filters.expiration_date', { namespace })}
                reactOptions={{ id: 'expiration-date' }}
              />
            )}

            {hasShowStatusFilter && (
              <div className="filters__option--native --active">
                <BooleanSelect
                  name="status"
                  value={state.filters.status}
                  label={translate('filters.status', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'status',
                    onChange: onChangeCommonFilter('status')
                  }}
                />
              </div>
            )}

            {hasShowAutoExtendFilter && (
              <div className="filters__option--native --active">
                <BooleanSelect
                  name="auto_extend"
                  value={state.filters.autoExtend}
                  label={translate('filters.auto_extend', { namespace })}
                  placeholder={I18n.t('generic.all')}
                  selectOptions={{
                    id: 'auto-extend',
                    onChange: onChangeCommonFilter('autoExtend')
                  }}
                />
              </div>
            )}

            {hasShowExpiredSeriesFilter && (
              <div className="filters__option--native --active">
                <BooleanSelect
                  name="expired_series"
                  value={state.filters.expiredSeries}
                  label={translate('filters.expired_series', { namespace })}
                  selectOptions={{
                    id: 'expired_series',
                    onChange: onChangeCommonFilter('expiredSeries')
                  }}
                />
              </div>
            )}
          </fieldset>
          <fieldset className="filters__group--actions">
            <div role="button" tabIndex="-3" className="filters__reset-action" onClick={onResetFilters}>
              {I18n.t('generic.reset')}
            </div>

            <div role="button" tabIndex="-4" className="filters__reset-action" onClick={onResetCache}>
              {I18n.t('generic.reset_cache')}
            </div>
          </fieldset>
        </article>
      )}

      {!hasDisableAllFilters && !hasDisableAllBulkActions && <hr className="workspace__split" />}

      {!hasDisableAllBulkActions && (
        <div className="qmb-content-actions">
          <div className="content-actions__group">
            <div role="button" tabIndex="0" className="qmb-control js-bulk-action" onClick={onBulkExtend} disabled>
              <i className="fa-light fa-rectangle-history-circle-plus" />
              {translate('table.buttons.bulk_extend', { namespace })}
            </div>
          </div>
        </div>
      )}

      <CustomScrollbar>
        <article className="--scroll-x --disable-back-gesture">
          <div ref={tableRef} id="inspection-series-grid" data-src-url={inspectionSeriesUrl} className="qmb-table" />
        </article>
      </CustomScrollbar>
    </div>
  );
}

IndexTable.propTypes = {
  inspectionSeriesUrl: PropTypes.string.isRequired,
  defaultHiddenColumns: PropTypes.arrayOf(PropTypes.string),
  nonHidableColumns: PropTypes.arrayOf(PropTypes.string),
  namespace: PropTypes.string,
  buildingsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  accountsForSelect: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      data: PropTypes.object
    })
  ),
  cacheKey: PropTypes.string,
  hasShowExpiredSeriesFilter: PropTypes.bool,
  hasShowExpirationDateFilter: PropTypes.bool,
  hasShowBuildingFilter: PropTypes.bool,
  hasShowAccountFilter: PropTypes.bool,
  hasShowStatusFilter: PropTypes.bool,
  hasShowAutoExtendFilter: PropTypes.bool,
  hasDisableAllFilters: PropTypes.bool,
  hasDisableAllBulkActions: PropTypes.bool,
  pageSize: PropTypes.number
};

IndexTable.defaultProps = {
  namespace: DEFAULT_NAMESPACE,
  buildingsForSelect: [],
  accountsForSelect: [],
  cacheKey: DEFAULT_CACHE_KEY,
  defaultHiddenColumns: [],
  nonHidableColumns: DEFAULT_NON_HIDABLE_COLUMNS,
  hasShowExpiredSeriesFilter: true,
  hasShowExpirationDateFilter: true,
  hasShowBuildingFilter: true,
  hasShowAccountFilter: true,
  hasShowStatusFilter: true,
  hasShowAutoExtendFilter: true,
  hasDisableAllFilters: false,
  hasDisableAllBulkActions: false,
  pageSize: DEFAULT_PAGE_SIZE
};

export default IndexTable;

/* eslint-enable react/no-unescaped-entities */
