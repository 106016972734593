import { translate } from '@utils/i18n';
import LinkTableCell from '@components/Kendo/LinkTableCell';
import { buildInitialState as buildInitialDateRangeState } from '@components/Kendo/DateRangePickerReducer';
import ActionsCell from './nested/ActionsCell';

export const CACHE_KEY = 'kendo/deficiencies/index';

export const NAMESPACE = 'features.deficiencies_index';

export const INITIAL_PAGE_SKIP = 0;

export const INITIAL_PAGE = { skip: INITIAL_PAGE_SKIP, take: 25 };

export const INITIAL_SORT = [
  {
    field: 'inspection',
    dir: 'desc'
  }
];

export const INITIAL_FILTERS = {
  values: {
    isResolved: '',
    building: '',
    territory: '',
    account: '',
    type: '',
    resolutionStatus: '',
    technician: null,
    deficiencyStatus: '',
    salesPerson: '',
    assets: '',
    ...buildInitialDateRangeState('dateOpened')
  },
  active: {
    isResolved: true,
    building: true,
    territory: true,
    account: true,
    type: true,
    resolutionStatus: true,
    technician: false,
    deficiencyStatus: true,
    salesPerson: false,
    dateOpened: true,
    assets: true
  },
  operators: {}
};

export const ALL_COLUMNS = [
  'selected',
  'inspection',
  'inspectionDate',
  'building',
  'dateOpened',
  'reasonForFailure',
  'systemAsset',
  'details',
  'deficiencyStatus',
  'resolutionStatus',
  'resolutionNotes',
  'notes',
  'internalNotes',
  'resolvedBy',
  'actions'
];

export const ALL_COLUMNS_DATA = {
  selected: {
    id: 'selected',
    field: 'selected',
    sortable: false,
    title: translate('table.headers.selected', { namespace: NAMESPACE }),
    className: 'k-table-td --selector',
    width: 50,
    headerClassName: 'k-table-th --selector'
  },
  inspection: {
    id: 'inspection',
    field: 'inspection',
    title: translate('table.headers.inspection', { namespace: NAMESPACE }),
    width: 130,
    cell: LinkTableCell
  },
  building: {
    id: 'building',
    field: 'building',
    title: translate('table.headers.building', { namespace: NAMESPACE }),
    width: 130,
    cell: LinkTableCell
  },
  dateOpened: {
    id: 'dateOpened',
    field: 'dateOpened',
    width: 130,
    title: translate('table.headers.date_opened', { namespace: NAMESPACE })
  },
  reasonForFailure: {
    id: 'reasonForFailure',
    field: 'reasonForFailure',
    title: translate('table.headers.reason_for_failure', { namespace: NAMESPACE }),
    width: 200,
    sortable: false
  },
  systemAsset: {
    id: 'systemAsset',
    field: 'systemAsset',
    title: translate('table.headers.system_asset', { namespace: NAMESPACE }),
    width: 140,
    sortable: false
  },
  details: {
    id: 'details',
    field: 'details',
    title: translate('table.headers.details', { namespace: NAMESPACE }),
    width: 200,
    sortable: false
  },
  deficiencyStatus: {
    id: 'deficiencyStatus',
    field: 'deficiencyStatus',
    width: 130,
    title: translate('table.headers.deficiency_status', { namespace: NAMESPACE })
  },
  resolutionStatus: {
    id: 'resolutionStatus',
    field: 'resolutionStatus',
    width: 130,
    title: translate('table.headers.resolution_status', { namespace: NAMESPACE })
  },
  resolutionNotes: {
    id: 'resolutionNotes',
    field: 'resolutionNotes',
    title: translate('table.headers.resolution_notes', { namespace: NAMESPACE }),
    width: 150,
    sortable: false
  },
  notes: {
    id: 'notes',
    field: 'notes',
    title: translate('table.headers.notes', { namespace: NAMESPACE }),
    width: 150,
    sortable: false
  },
  internalNotes: {
    id: 'internalNotes',
    field: 'internalNotes',
    title: translate('table.headers.internal_notes', { namespace: NAMESPACE }),
    width: 150,
    sortable: false
  },
  inspectionDate: {
    id: 'inspectionDate',
    field: 'inspectionDate',
    width: 130,
    title: translate('table.headers.inspection_date', { namespace: NAMESPACE })
  },
  resolvedBy: {
    id: 'resolvedBy',
    field: 'resolvedBy',
    width: 150,
    title: translate('table.headers.resolved_by', { namespace: NAMESPACE })
  },
  actions: {
    id: 'actions',
    field: 'actions',
    title: translate('table.headers.actions', { namespace: NAMESPACE }),
    sortable: false,
    width: 130,
    cell: ActionsCell
  }
};
