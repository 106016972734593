import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '@utils/i18n';
import UpdateStatusModal from './UpdateStatusModal';
import ExportCsvButton from './ExportCsvButton';

const namespace = 'features.deficiencies_index.mass_actions';

function MassActions({ state, onSelectAll, queryParams }) {
  const { data: deficiencies, selectAll, totalCount } = state;
  const selectedDeficiencies = deficiencies.filter((deficiency) => deficiency.selected);
  const disabled = selectedDeficiencies.length === 0;
  const deficienciesCount = selectAll ? totalCount : selectedDeficiencies.length;
  const deficiencyIds = selectedDeficiencies.map((deficiency) => deficiency.id).join();

  return (
    <div className="qmb-actions">
      {!disabled && (
        <div className="actions__group--selection">
          <button type="button" className="actions__selection">
            <span className="actions__count">{deficienciesCount}</span>
          </button>
          {!selectAll && (
            <div className="actions__entire">
              <button type="button" tabIndex="0" className="qmb-control--sm" onClick={onSelectAll}>
                {translate('selected_global_counter', { namespace, count: totalCount })}
              </button>
            </div>
          )}
        </div>
      )}

      <div className="actions__group">
        <UpdateStatusModal
          deficienciesCount={deficienciesCount}
          deficiencyIds={!selectAll && deficiencyIds}
          disabled={disabled}
          queryParams={queryParams}
        />
        <ExportCsvButton
          deficienciesCount={deficienciesCount}
          deficiencyIds={!selectAll && deficiencyIds}
          disabled={disabled}
          queryParams={queryParams}
        />
      </div>
    </div>
  );
}

MassActions.propTypes = {
  state: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object),
    totalCount: PropTypes.number,
    selectAll: PropTypes.bool
  }).isRequired,
  onSelectAll: PropTypes.func.isRequired,
  queryParams: PropTypes.object.isRequired
};

export default MassActions;
