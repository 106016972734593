import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { move, stopDragging, types } from '../Redux/draggingSlice';
import ShallowEvent from './ShallowEvent';
import ShallowTechnician from './ShallowTechnician';
import ShallowVisit from './ShallowVisit';

function DragItem({ parentBoundingBox }) {
  const mouseX = useSelector((state) => state.dragging.mouseX);
  const mouseY = useSelector((state) => state.dragging.mouseY);
  const element = useSelector((state) => state.dragging.element);
  const type = useSelector((state) => state.dragging.type);
  const data = useSelector((state) => state.dragging.data);
  const dispatch = useDispatch();

  const HandleMouseMove = (e) => {
    dispatch(move({ x: e.clientX, y: e.clientY }));
    document.addEventListener('mouseup', HandleMouseUp);
  };

  const HandleMouseUp = (e) => {
    document.removeEventListener('mousemove', HandleMouseMove);
    document.removeEventListener('mouseup', HandleMouseUp);
    dispatch(stopDragging());
  };

  const posX = () => {
    return mouseX - parentBoundingBox.left - 25;
  };

  const posY = () => {
    return mouseY - parentBoundingBox.top - 5;
  };

  useEffect(() => {
    document.addEventListener('mousemove', HandleMouseMove);
    document.addEventListener('mouseup', HandleMouseUp);

    return () => {
      document.removeEventListener('mousemove', HandleMouseMove);
      document.removeEventListener('mouseup', HandleMouseUp);
    };
  }, []);

  const contents = () => {
    switch (type) {
      case types.EVENT:
        return <ShallowEvent event={data} />;
      case types.TECHNICIAN:
        return <ShallowTechnician technician={data} />;
      case types.VISIT:
        return <ShallowVisit visit={data} />;
      default:
        return <div style={{ background: 'red' }}>No Content</div>;
    }
  };

  return (
    <div
      className="workspace__draggable"
      style={{
        pointerEvents: 'none',
        left: posX(),
        top: posY()
      }}>
      {contents()}
    </div>
  );
}

export default DragItem;
