import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import EventClusteringAlgorithm from './EventClusteringAlgorithm';
import { loadCacheFromLocalStorage } from '../../../helpers/localStorage';
import { useSelector } from 'react-redux';

export const status = {
  READY: 'READY',
  LOADING: 'LOADING',
  SAVING: 'SAVING',
  ERROR: 'ERROR',
  REPOSITIONING: 'REPOSITIONING',
  RESIZING: 'RESIZING',
  DRAGGING: 'DRAGGING'
};

export const actions = {
  NONE: 'NONE',
  RESIZING_LEFT: 'RESIZING_LEFT',
  RESIZING_RIGHT: 'RESIZING_RIGHT',
  SNAPPING_LEFT: 'SNAPPING_LEFT',
  SNAPPING_RIGHT: 'SNAPPING_RIGHT',
  SAMPLING_VISIT: 'SAMPLING_VISIT'
};

const initialFilterState = {
  values: { technicianTeam: '' },
  active: { technicianTeam: false }
};

const buildInitialState = () => {
  const savedState = loadCacheFromLocalStorage('kendo/map_scheduler');
  const savedStateScheduler = loadCacheFromLocalStorage('kendo/map_scheduler/scheduler');
  return {
    filters: savedStateScheduler?.filters || initialFilterState,
    status: status.READY,
    action: actions.NONE,
    activeDay: null,
    activeEvent: null,
    technicians: [],
    events: [],
    saveQueue: [],
    errorList: [],
    activeLaneID: null,
    schedulerActive: savedState?.stateFilters?.boardActive !== false,
    visitStatusScheduledActive: savedState?.stateFilters?.visitStatusScheduledActive !== false,
    visitStatusOnAssign: null,
    settings: null
  };
};

export const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState: buildInitialState(),
  reducers: {
    toggleScheduler: (state, action) => {
      state.schedulerActive = !state.schedulerActive;
    },
    toggleVisitStatusScheduled: (state, action) => {
      state.visitStatusScheduledActive = !state.visitStatusScheduledActive;
    },
    pushToSaveQueue: (state, action) => {
      state.saveQueue.push(action.payload);
    },
    shiftSaveQueue: (state, action) => {
      state.saveQueue.shift();
    },
    revertAndShiftSaveQueue: (state, action) => {
      const event = state.saveQueue.shift();

      if (event) {
        const index = state.events.findIndex((x) => x.key === event.key);
        if (index !== -1) {
          const allEvents = [...state.events];
          const curEvent = { ...allEvents[index] };
          curEvent.startTime = curEvent.metadata.prevStartTime;
          curEvent.endTime = curEvent.metadata.prevEndTime;
          curEvent.technicianId = curEvent.metadata.prevTechnicianId;
          allEvents[index] = curEvent;
          state.events = ProcessEvents(allEvents.map((e) => ({ ...e })));
        }
      }
    },
    setInitialActiveDay: (state, action) => {
      state.timeZone = action.payload.timeZone
      state.activeDay = DateTime.now().setZone(action.payload.timeZone).toISODate();
    },
    incrementActiveDay: (state, action) => {
      state.activeDay = DateTime.fromISO(state.activeDay).plus({days: 1}).toISODate();
    },
    decrementActiveDay: (state, action) => {
      state.activeDay = DateTime.fromISO(state.activeDay).minus({days: 1}).toISODate();
    },
    setActiveDayToToday: (state, action) => {
      state.activeDay = DateTime.now().setZone(state.timeZone).toISODate();
    },
    setActiveDay: (state, action) => {
      state.activeDay = DateTime.fromISO(action.payload).toISODate();
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setTechnicians: (state, action) => {
      state.technicians = action.payload.map((t) => ({ ...t, showOnMap: true }));
    },
    setEvents: (state, action) => {
      action.payload.filter((event) => event.all_day).forEach((event) => {
        event.endTime = DateTime.fromISO(event.endTime, { zone: state.timeZone }).endOf('day').toString();
        event.startTime = DateTime.fromISO(event.startTime, { zone: state.timeZone }).toString();
      });

      state.events = ProcessEvents(action.payload.map((e) => ({ ...e }))).map((e) => ({
        ...e,
        metadata: {
          ...e.metadata,
          originalStartTime: e.startTime,
          originalEndTime: e.endTime,
          prevStartTime: e.startTime,
          prevEndTime: e.endTime
        }
      }));
    },
    addEvent: (state, action) => {
      const { data } = action.payload;
      const allEvents = [...state.events];
      const newEvent = JSON.parse(JSON.stringify(data));

      // whoever gonna get rid of Inspection#status - keep an eye on that place
      if (newEvent.type === 'Visit' && state.visitStatusScheduledActive) {
        newEvent.status = 'scheduled';
      } else if (
        (newEvent.type === 'Inspection' || newEvent.type === 'InspectionVisit') &&
        state.visitStatusScheduledActive
      ) {
        const { oldStatus, statusColor, statusIcon, humanizedStatus } = state.visitStatusOnAssign;

        newEvent.status = oldStatus;
        newEvent.statusColor = statusColor;
        newEvent.statusIcon = statusIcon;
        newEvent.humanizedStatus = humanizedStatus;
      }

      allEvents.push(newEvent);
      state.events = ProcessEventsSubset(allEvents, newEvent.technicianId);
    },
    deleteEvent: (state, action) => {
      const updatedEvents = state.events.filter((x) => x.key !== action.payload);
      state.events = ProcessEvents(updatedEvents.map((e) => ({ ...e })));
    },
    startResizingEvent: (state, action) => {
      const { key, isResizingLeft, isSnapping } = action.payload;

      const selectedEvent = state.events.find((x) => x.key === key);
      selectedEvent.metadata.resizing = true;
      selectedEvent.metadata.prevStartTime = selectedEvent.startTime;
      selectedEvent.metadata.prevEndTime = selectedEvent.endTime;
      selectedEvent.metadata.prevTechnicianId = selectedEvent.technicianId;

      state.activeEvent = selectedEvent;
      state.status = status.RESIZING;
      if (isSnapping) state.action = isResizingLeft ? actions.SNAPPING_LEFT : actions.SNAPPING_RIGHT;
      else state.action = isResizingLeft ? actions.RESIZING_LEFT : actions.RESIZING_RIGHT;
    },
    resizeEvent: (state, action) => {
      const { key, newTime } = action.payload;
      const index = state.events.findIndex((x) => x.key === key);
      if (index !== -1) {
        const allEvents = [...state.events];
        const curEvent = { ...allEvents.find((x) => x.key === key) };

        if (state.action === actions.RESIZING_LEFT || state.action === actions.SNAPPING_LEFT) {
          if (moment(curEvent.endTime).diff(moment(newTime), 'minutes') <= 15) return;
          curEvent.startTime = newTime;
        } else if (state.action === actions.RESIZING_RIGHT || state.action === actions.SNAPPING_RIGHT) {
          if (moment(newTime).diff(moment(curEvent.startTime), 'minutes') <= 15) return;
          curEvent.endTime = newTime;
        }
        allEvents[index] = curEvent;
        state.events = ProcessEventsSubset(allEvents, curEvent.technicianId);
      }
    },
    stopResizingEvent: (state, action) => {
      const selectedEvent = state.events.find((x) => x.key === action.payload);
      selectedEvent.metadata.resizing = false;

      state.saveQueue.push(selectedEvent);
      state.activeEvent = null;
      state.status = status.READY;
      state.action = actions.NONE;
    },
    startRepositioningEvent: (state, action) => {
      const { key, minuteOffset, isSampling } = action.payload;
      const selectedEvent = state.events.find((x) => x.key === key);
      selectedEvent.metadata.repositioning = true;
      selectedEvent.metadata.minuteOffset = minuteOffset;
      selectedEvent.metadata.prevStartTime = selectedEvent.startTime;
      selectedEvent.metadata.prevEndTime = selectedEvent.endTime;
      selectedEvent.metadata.prevTechnicianId = selectedEvent.technicianId;

      if (isSampling) {
        state.action = actions.SAMPLING_VISIT;
      }

      state.activeEvent = selectedEvent;
      state.status = status.REPOSITIONING;
    },
    repositionEvent: (state, action) => {
      const { key, targetTime } = action.payload;
      const index = state.events.findIndex((x) => x.key === key);
      if (index !== -1) {
        const allEvents = [...state.events];
        const curEvent = { ...allEvents.find((x) => x.key === key) };

        const duration = moment(curEvent.endTime).diff(moment(curEvent.startTime), 'minutes');
        const startDestination = moment(targetTime).toISOString();
        const endDestination = moment(targetTime).add(duration, 'minutes').toISOString();

        curEvent.startTime = startDestination;
        curEvent.endTime = endDestination;
        allEvents[index] = curEvent;
        state.events = ProcessEventsSubset(allEvents, curEvent.technicianId);

        if (curEvent.key === state.activeEvent?.key) {
          state.activeEvent = curEvent;
        }
      }
    },
    stopRepositioningEvent: (state, action) => {
      const { key, save } = action.payload;

      const selectedEvent = state.events.find((x) => x.key === key);
      selectedEvent.metadata.repositioning = false;
      selectedEvent.metadata.minuteOffset = 0;

      if (save) {
        state.saveQueue.push(selectedEvent);
      }

      state.activeEvent = null;
      state.status = status.READY;
      state.action = actions.NONE;
    },
    reassignEvent: (state, action) => {
      const { eventKey, techId, save, technician } = action.payload;

      const index = state.events.findIndex((x) => x.key === eventKey);
      if (index !== -1) {
        const allEvents = [...state.events];
        const curEvent = allEvents[index];
        curEvent.technician = technician;
        curEvent.technicianId = techId;
        allEvents[index] = curEvent;

        if (curEvent.key === state.activeEvent?.key) {
          state.activeEvent = curEvent;
        }

        if (save) {
          state.saveQueue.push(curEvent);
        }

        state.events = ProcessEvents(allEvents.map((e) => ({ ...e })));
      }
    },
    unassignEvent: (state, action) => {
      const { event } = action.payload;

      event.technicianId = null;
      event.startTime = event.metadata.prevStartTime;
      event.endTime = event.metadata.prevEndTime;

      state.saveQueue.push(event);

      const index = state.events.findIndex((x) => x.key === event.key);
      if (index !== -1) {
        const allEvents = [...state.events];
        const curEvent = allEvents[index];
        curEvent.technicianId = null;
        allEvents[index] = curEvent;
        state.events = ProcessEvents(allEvents.map((e) => ({ ...e })));
      }
    },
    replaceSchedulerEvent: (state, action) => {
      const index = state.events.findIndex((x) => x.key === action.payload.key);
      const allEvents = [...state.events];

      // whoever gonna get rid of Inspection#status - keep an eye on that place
      const cancelledStatuses = [
        'deleted_by_technician',
        'cancelled',
        'Cancelled',
        'cancelled_by_parent_tenant',
        'DeletedByTechnician'
      ];
      if (index !== -1) {
        // Found in scheduler
        if (cancelledStatuses.findIndex((x) => x === action.payload.status) >= 0) {
          allEvents.splice(index, 1);
          state.visits = allEvents;
        } else {
          allEvents[index] = action.payload;
        }
      } else if (cancelledStatuses.findIndex((x) => x === action.payload.status) === -1) {
        allEvents.push(action.payload);
      }
      state.events = ProcessEvents(allEvents.map((e) => ({ ...e })));
    },
    toggleTechnician: (state, action) => {
      const technician = state.technicians.find((x) => x.id === action.payload);
      technician.showOnMap = !technician.showOnMap;
    },
    addError: (state, action) => {
      const key = `${new Date().getTime()} ${Math.floor(Math.random() * 10000)}`;
      state.errorList.push({ key, message: action.payload });
    },
    removeError: (state, action) => {
      console.log(action.payload);
      state.errorList = state.errorList.filter((e) => e.key !== action.payload);
    },
    setActiveLaneId: (state, action) => {
      state.activeLaneID = action.payload;
    },
    setFilterValue: (state, action) => {
      const { field, value } = action.payload;
      state.filters.values[field] = value;
    },
    toggleFilter: (state, action) => {
      const { field } = action.payload;

      state.filters.active[field] = !state.filters.active[field];

      state.filters.values[field] = '';
    },
    resetFilters: (state, action) => {
      state.filters = initialFilterState;
    },
    setVisitStatusOnAssign: (state, action) => {
      state.visitStatusOnAssign = action.payload;
    }
  }
});

const ProcessEventsSubset = (events, techId) => {
  const techEvents = events.filter((x) => x.technicianId === techId);
  const nonTechEvents = events.filter((x) => x.technicianId !== techId);
  return nonTechEvents.concat(EventClusteringAlgorithm(techEvents.map((e) => ({ ...e }))));
};

const ProcessEvents = (normalizedEvents) => {
  const eventsGroupedByTech = GroupByProperty(normalizedEvents, 'technicianId');
  for (const tech in eventsGroupedByTech) {
    eventsGroupedByTech[tech] = EventClusteringAlgorithm(eventsGroupedByTech[tech]);
  }
  return Object.values(eventsGroupedByTech).flatMap((events) => events);
};

function GroupByProperty(arr, property) {
  return arr.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

export const {
  pushToSaveQueue,
  shiftSaveQueue,
  revertAndShiftSaveQueue,
  incrementActiveDay,
  decrementActiveDay,
  setActiveDayToToday,
  setActiveDay,
  setStatus,
  setData,
  setEvents,
  addEvent,
  deleteEvent,
  startResizingEvent,
  resizeEvent,
  stopResizingEvent,
  startRepositioningEvent,
  stopRepositioningEvent,
  repositionEvent,
  reassignEvent,
  unassignEvent,
  replaceSchedulerEvent,
  toggleTechnician,
  addError,
  removeError,
  setActiveLaneId,
  toggleScheduler,
  toggleVisitStatusScheduled,
  setFilterValue,
  toggleFilter,
  resetFilters,
  setVisitStatusOnAssign,
  setInitialActiveDay,
  setTechnicians
} = schedulerSlice.actions;

export default schedulerSlice.reducer;
